const routes = require('./index')

const getRouteByPath = (path) => {
  const { routes: routesArr } = routes
  return (routesArr.find((routeObj) => routeObj.page === path) || {}).name
}

module.exports = {
  getRouteByPath: getRouteByPath
}
