import React from 'react'
import bemCx from 'bem-modifiers'
import { withNamespaces } from '>/i18n'

import FestivalInfo from '~/components/FestivalInfo'
import HeroFilters from '~/components/HeroFilters/HeroFiltersContainer'

@withNamespaces('common')
export default class TakePartSection extends React.Component {

  get buttonLabel () {
    const { festivalData, t } = this.props

    if (this.isMyReservationPage) return t('take_part.button_cta')

    return festivalData
      ? t('hero_section.search_button')
      : t('hero_section.no_festival_button')
  }

  get isMyReservationPage () {
    return this.props.router.pathname === '/my-reservations'
  }

  get buttonModifiers () {
    const { festivalName } = this.props

    return festivalName === 'rw'
      ? ['full-width', 'hero', 'big']
      : ['full-width', 'hero', 'big', 'with-glow']
  }

  render () {
    const { t, modifiers, festivalData, festivalName, withRespect } = this.props

    return (
      <div className={bemCx('take-part-section', modifiers)}>
        <div className='take-part-section__content container'>
          {this.isMyReservationPage
            ? <span
              className='take-part-section__no-reservations'
              dangerouslySetInnerHTML={{ __html: t('take_part.no_reservations') }}
            />
            : <FestivalInfo
              festival={festivalData}
              className='take-part-section__event'
              withRespect={withRespect}
            />
          }
          <div
            className='take-part-section__title'
            dangerouslySetInnerHTML={{ __html: festivalName === 'dw' ? t('take_part.title_dw') : t('take_part.title') }}
          />
          <HeroFilters
            locationModifiers={['geolocation', 'hero']}
            peopleModifiers={['people']}
            buttonModifiers={this.buttonModifiers}
            buttonLabel={this.buttonLabel}
            iconRight={festivalName === 'rw' ? 'arrow-long' : undefined}
            festivalData={festivalData}
          />
        </div>
      </div>
    )
  }
}
