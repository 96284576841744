import { FESTIVAL } from './festivalTypes'
import { processEnv } from '~/utils/helpers'

export const DEFAULT_FESTIVAL = 'rw'

export default (festivalName) => {
  const festival = FESTIVAL(festivalName)

  const def = {
    festival,
    enableAnalytics: false,
    enableFestivalParam: true,
    facebookAppId: 331488624530952,
    appleServiceId: 'pl.restaurantweek.restaurantclub.service.dev',
    apiUrl: processEnv.API_URL || 'https://api.restaurantclub.pl',
    googleAPIKey: processEnv.GOOGLE_API_KEY,
    sentryDsn: processEnv.SENTRY_DSN,
    sentryRelease: processEnv.SENTRY_RELEASE || '',
    sentryEnv: processEnv.NEXT_ENV || 'unknown',
    mapboxToken: processEnv.MAPBOX_TOKEN,
    gtm_key: 'GTM-TBKQM48',
    clubUrl: processEnv.CLUB_URL || 'https://restaurantclub.pl',
    promotedFestivals: { neon: '11', rctjz: '37' },
    salt: processEnv.SALT
  }
  const development = {
    ...def,
  }
  const review = {
    ...def,
  }
  const staging = {
    ...def,
  }
  const demo = {
    ...def,
    appleServiceId: processEnv.APPLE_SERVICE_ID || 'pl.restaurantweek.restaurantclub.service.demo',
    promotedFestivals: { neon: '7', rctjz: '5' },
  }
  const production = {
    ...def,
    enableAnalytics: true,
    enableFestivalParam: false,
    facebookAppId: 160962760905642,
    appleServiceId: processEnv.APPLE_SERVICE_ID || 'pl.restaurantweek.restaurantclub.service',
    promotedFestivals: { neon: '23', rctjz: '25' },
  }
  const config = {
    development,
    review,
    staging,
    demo,
    production
  }
  const testing = {
    ...development,
    festival: FESTIVAL(DEFAULT_FESTIVAL)
  }
  return config[processEnv.NEXT_ENV] || testing
}
