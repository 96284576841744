import useFestivalContext from '~/hooks/useFestivalContext'
import { withRouter } from 'next/router'

export default WrappedComponent => withRouter(({ router, ...props }) => {
  const { festivalName, festivalId } = useFestivalContext({ router })

  return (
    <WrappedComponent
      {...props}
      router={router}
      festivalName={festivalName}
      festivalId={festivalId}
    />
  )
})
