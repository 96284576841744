import { googleApiAjaxer } from '~/api/common'
import config from '~/config'

// external urls
export const manageGeocoding = {
  fetch: latlng => googleApiAjaxer({
    url: '/maps/api/geocode/json',
    query: { latlng, key: config().googleAPIKey }
  })
}
