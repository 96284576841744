import React from 'react'

import DropdownSelect from '~/components/DropdownSelect'
import { withNamespaces } from '>/i18n'
import useLanguageSwitcher from '~/hooks/useLanguageSwitcher'

export default withNamespaces(['languages'])(({ t, lng }) => {
  const { options, onLanguageChange } = useLanguageSwitcher({ t, lng })

  if (options.length <= 1) {
    return null
  }

  return (
    <div className='language-dropdown-select language-select'>
      <DropdownSelect
        instanceId='language-footer-select'
        valueContainerIcon='world'
        value={{ value: lng, label: t(lng) }}
        options={options}
        onChange={onLanguageChange}
        isSearchable={false}
        hideSelectedOptions={false}
      />
    </div>
  )
})
