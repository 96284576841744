import fetch from 'isomorphic-fetch'
import { format } from 'url'

const GOOGLE_URL = 'https://maps.googleapis.com'
const withGoogleApiHost = (path) => `${GOOGLE_URL}${path}`

const catchFailure = (res) => {
  if (res && res.status >= 400) {
    return Promise.reject(res)
  }
  return res
}

const formatResponse = async response => {
  let parsedJson
  try {
    if (response.status === 204) {
      parsedJson = null
    } else {
      parsedJson = await response.json()
    }
  } catch (e) {
    parsedJson = null
  }

  return parsedJson
}

const googleAjaxer = ({ url, method = 'GET', query }) => {
  const fullUrl = query ? `${url}${format({ query })}` : url
  return fetch(
    fullUrl,
    {
      method,
    }
  )
    .then(catchFailure)
    .then(formatResponse)
}

export const googleApiAjaxer = ({ url, ...props }) => googleAjaxer({ url: withGoogleApiHost(url), ...props })
