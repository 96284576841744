import React from 'react'

import DropdownSelect from '~/components/DropdownSelect'

export default ({
  citiesOptions,
  changeSelectedCity,
  selectedLocation = {},
  className = 'geolocation-dropdown-select__select',
  modifiers,
  instanceId,
  disabled
}) => {
  const fotmettedSelectedLocation = { value: selectedLocation.id, label: selectedLocation.name }

  return (
    <DropdownSelect
      instanceId={instanceId}
      className={className}
      modifiers={modifiers}
      valueContainerIcon='dart'
      defaultValue={fotmettedSelectedLocation}
      value={fotmettedSelectedLocation}
      options={citiesOptions}
      onChange={city => changeSelectedCity({ id: +city.value, name: city.label })}
      isSearchable={false}
      hideSelectedOptions={false}
      disabled={disabled}
    />
  )
}
