import { useEffect, useState } from 'react'

import { hasWindow, scrollBarWidth, toggleBodyScroll, viewportWidth } from '~/utils/helpers'

export const useScrollLock = (hideOthers) => {
  const [scrollLockData, setScrollLockData] = useState({
    previousScrollY: hasWindow() && window.scrollY,
    previousHeaderPosition: null,
    scrollBarWidth: scrollBarWidth(),
  })

  let headerElement, headerElementPosition, bodyElement

  const getHeaderElement = () => {
    headerElement = hasWindow() && document.querySelector('.header')
    headerElementPosition = headerElement && window.getComputedStyle(headerElement).getPropertyValue('position')

    setScrollLockData({
      ...scrollLockData,
      previousHeaderPosition: headerElementPosition,
    })
  }

  const lockScroll = () => {
    const { previousScrollY, previousHeaderPosition, scrollBarWidth } = scrollLockData
    let htmlPosition = 'fixed'

    if (hasWindow() && hideOthers) {
      htmlPosition = viewportWidth() < 768 ? 'static' : 'fixed'
      bodyElement = document.querySelector('body')
      bodyElement.classList.add('modal-open')
    }
    toggleBodyScroll(true, previousScrollY, previousHeaderPosition, scrollBarWidth, htmlPosition)
  }

  const unlockScroll = () => {
    const { previousScrollY, previousHeaderPosition, scrollBarWidth } = scrollLockData

    bodyElement && bodyElement.classList.remove('modal-open')

    toggleBodyScroll(false, previousScrollY, previousHeaderPosition, scrollBarWidth)
  }

  useEffect(getHeaderElement, [])
  useEffect(() => {
    lockScroll()
    return unlockScroll
  }, [])
}
