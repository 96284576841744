import { useQuery } from '@apollo/client'
import get from 'lodash/get'
import gql from 'graphql-tag'

import withFestivalContext from '~/hoc/withFestivalContext'

import GeolocationDropdownSelect from './GeolocationDropdownSelectContainer'

import { GET_ACTIVE_REGIONS, GET_FESTIVAL_REGIONS } from '~/utils/queries.gql'

export const GET_LOCATION = gql`
  query locationData {
    location @client {
      id
      name
    }
  }
`

const GET_EVENTS_REGIONS = gql`
  query eventsRegionsList ($slotless: Boolean) {
    incomingEventsRegions (slotless: $slotless) {
      id
      name
    }
  }
`

export default withFestivalContext(({ festivalId, router, ...props }) => {
  const isEventPage = router.route === '/side-events-listing'

  const { data: activeRegionsData } = useQuery(GET_ACTIVE_REGIONS, {
    skip: isEventPage || festivalId
  })
  const { data: festivalRegionsData } = useQuery(GET_FESTIVAL_REGIONS, {
    variables: { festivalEditionId: festivalId },
    skip: isEventPage || !festivalId
  })
  const { data: eventRegionsData } = useQuery(GET_EVENTS_REGIONS, {
    variables: { slotless: false },
    skip: !isEventPage
  })

  return (
    <GeolocationDropdownSelect
      {...props}
      router={router}
      regions={
        get(activeRegionsData, 'regions') ||
        get(festivalRegionsData, 'festivalRegions') ||
        get(eventRegionsData, 'incomingEventsRegions')
      }
    />
  )
})
