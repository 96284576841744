import React, { createContext, Component } from 'react'

export const ModalContext = createContext({
  modals: [],
  openModal: () => {},
  closeModal: () => {},
})

const openModalFn = (prevModals, name, data) => {
  const isUnique = !prevModals.some(modal => modal.name === name)

  return isUnique
    ? {
      modals: [...prevModals, { name, data }],
    }
    : prevModals
}

const closeModalFn = (prevModals, name) => {
  const index = prevModals.findIndex(modal => modal.name === name)
  index >= 0 && prevModals.splice(index, 1)

  return {
    modals: [...prevModals]
  }
}

export class ModalProvider extends Component {
  state = {
    modals: [],
    openModal: (name, data) => {
      this.setState(prevState => openModalFn(prevState.modals, name, data))
    },
    closeModal: ({ name }) => {
      this.setState(prevState => closeModalFn(prevState.modals, name))
    }
  }

  render () {
    return (
      <ModalContext.Provider value={this.state}>
        {this.props.children}
      </ModalContext.Provider>
    )
  }
}

export const ModalConsumer = ModalContext.Consumer
