export const MIN_NUMBER_OF_ATTENDEES = 1
export const MAX_NUMBER_OF_ATTENDEES = 8
export const INITIAL_NUMBER_OF_ATTENDEES = 2

export const DEFAULT_LOCATION = { label: 'Warszawa', value: 5 }

export const VOIVODESHIP_CAPITAL = {
  mazowieckie: 'Warszawa',
  podlaskie: 'Białystok',
  lubelskie: 'Lublin',
  'warmińsko-mazurskie': 'Olsztyn',
  pomorskie: 'Gdańsk',
  zachodniopomorskie: 'Szczecin',
  lubuskie: 'Zielona Góra',
  wielkopolskie: 'Poznań',
  dolnośląskie: 'Wrocław',
  śląskie: 'Katowice',
  opolskie: 'Opole',
  małopolskie: 'Kraków',
  lódzkie: 'Łódź',
  'kujawsko-pomorskie': 'Bydgoszcz',
  świętokrzyskie: 'Kielce',
  podkarpackie: 'Rzeszów',
}

export const ACCESS = {
  ALL: 'all',
  LOGGED: 'logged',
}

export const TAGS = {
  ATMOSPHERE: 'Atmosphere',
  CATEGORY: 'Category',
  CUISINE: 'Cuisine',
  DIET: 'Diet',
  DISH: 'Dish',
  ESTABLISHMENT_TYPE: 'EstablishmentType',
  OCCASION: 'Occasion',
  OTHER: 'Other',
  PRICE: 'Price',
  RESERVATION_TYPE: 'ReservationType',
  DELIVERY_REGION: 'DeliveryRegion',
  FESTIVAL: 'festivalFilter' // in fact it is NOT a tag
}

export const TAG_TO_OMIT = [
  TAGS.PRICE,
  TAGS.RESERVATION_TYPE
]

export const EXPLANATION_SECTION_TOP_MARGIN = 150

export const START_DATE = 'startDate'
export const END_DATE = 'endDate'

export const MODAL_NAMES = {
  CONFIRM_LEGAL_AGE: 'confirm-legal-age',
  NEWSLETTER: 'newsletter',
  NEWSLETTER_EXIT: 'newsletter-exit',
  USER_BILLING: 'user-billing-data',
  EXPIRED_ORDER: 'expired-order',
  EVALUATION: 'evaluation',
  AUTHENTICATE: 'authenticate',
  CANCEL_RESERVATION: 'cancel-reservation',
  RESTAURANT: 'restaurant',
  CROSS_SELL_DIFF: 'cross-sells-diff',
  INVALID_RESERVATION: 'invalid-reservation',
  XMAS_REMINDER: 'xmas-reminder',
  DELIVERY_REMINDER: 'delivery-reminder',
  COVID_19: 'covid-19',
  IMPROPER_USER: 'improper-user',
  COOKIE: 'cookie',
  COKE: 'coke',
  REGULATION: 'regulation',
}

export const FESTIVAL_EDITION_STATES = {
  VOUCHER: 'VOUCHER',
  PRESALE: 'PRESALE',
  SALE: 'SALE'
}

export const SPONSOR = {
  MAIN: 'MAIN',
  PARTNER: 'PARTNER',
  PATRON: 'PATRON',
  REGIONAL: 'REGIONAL',
  REGULAR: 'REGULAR'
}

export const RESERVABLE_TYPES = {
  EVENT: 'Event',
  DAILY: 'Daily',
  FER: 'FestivalEditionRestaurant'
}

export const MOBILE_APP_LINK = {
  ANDROID: 'https://play.google.com/store/apps/details?id=pl.restaurantweek.restaurantclub',
  IOS: 'https://itunes.apple.com/app/id1490565530'
}

export const DEFAULT_HEADER_PAGES = [
  '/faq',
  '/regulations',
  '/become-partner',
]

export const HEADER_LINK_NAMES = {
  RESTAURANTS: 'restaurants',
  ABOUT: 'about',
  FAQ: 'faq',
  PARTNERS: 'partners',
  VOUCHER: 'voucher',
  SIDE_EVENTS: 'side-events',
  GIFT_EVENTS: 'gift-events',
  GROUP_RESERVATIONS: 'group-reservations',
  JOIN_US: 'join-us'
}

export const COMMON_HEADER_LINKS = [
  HEADER_LINK_NAMES.RESTAURANTS,
  HEADER_LINK_NAMES.ABOUT,
  HEADER_LINK_NAMES.FAQ,
  HEADER_LINK_NAMES.PARTNERS,
  HEADER_LINK_NAMES.VOUCHER,
  HEADER_LINK_NAMES.GIFT_EVENTS
]

export const REGULATION_TYPES = {
  MAIN_REGULATION: 'MAIN_REGULATION',
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  REGULATION: 'REGULATION',
  OTHER_REGULATION: 'OTHER_REGULATION'
}

export const WEEK = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']

export const REGULATION_STATUS = {
  ACCEPTED: 'ACCEPTED',
  EXPIRED: 'EXPIRED',
  NOT_ACCEPTED: 'NOT_ACCEPTED'
}
