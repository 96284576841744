import { TAG_TO_OMIT, TAGS, COMMON_HEADER_LINKS, HEADER_LINK_NAMES } from '~/utils/consts'

export default {
  name: 'Fine Dining Week',
  id: 'festival-fdw',

  url: 'finediningweek.pl',
  query: 'fdw',

  fb: 'https://www.facebook.com/FineDiningWeek',
  instagram: 'https://www.instagram.com/explore/tags/restaurantweekpolska',
  email: 'info@finediningweek.pl',
  showAd: true,

  faq: {
    offset: {
      mobile: 22,
      tablet: 28,
      desktop: 40,
      intersection: 26,
    }
  },

  tagsToOmit: [...TAG_TO_OMIT, TAGS.DELIVERY_REGION],
  headerLinks: [...COMMON_HEADER_LINKS, HEADER_LINK_NAMES.SIDE_EVENTS]
}
