import { useRef } from 'react'
import bemCx from 'bem-modifiers'

import { useOnClickOutside } from '~/hooks/useOnClickOutside'

const ModalView = ({
  components,
  animation,
  uncloseable,
  closeModal,
  amimateOverlay,
  activeModal
}) => {
  const modalContent = useRef(null)
  useOnClickOutside(modalContent, closeModal)

  return (
    <div
      className={bemCx(
        'modal__page-overlay',
        [activeModal, { uncloseable, [animation]: amimateOverlay }]
      )}
    >
      {activeModal && <div
        className={bemCx('modal', [activeModal, animation])}
        ref={modalContent}
      >
        {!uncloseable &&
        <span
          className='modal__close-button i-close'
          onClick={closeModal}
        />}
        {components && components[activeModal] && components[activeModal]({
          closeThisModal: () => closeModal({ forceClose: true })
        })}
      </div>}
    </div>
  )
}

export default ModalView
