import React from 'react'
import bemCx from 'bem-modifiers'

import HomepageSectionWrapper from '~/components/landing-common/HomepageSectionWrapper'
import get from 'lodash/get'

export default class MediaPatronageSection extends React.Component {

  render () {
    const { title, options, pageModifier, inView } = this.props

    return (
      <HomepageSectionWrapper
        title={title}
        modifiers={['centered', 'media-patrons', pageModifier]}
      >
        <div className={bemCx('media-patronage-section')}>
          {options.map((option, i) => (
            <a
              key={i}
              className='media-patronage-section__regular-media-patron'
              href={option.url}
              target='_blank'
              rel='noreferrer noopener'
            >
              {inView && <img
                className='media-patronage-section__image'
                src={get(option, 'image.preview')}
                alt='Logo sponsora'
              />}
            </a>
          ))}
        </div>
      </HomepageSectionWrapper>
    )
  }
}
