import React from 'react'
import { withNamespaces } from '>/i18n'
import bemCx from 'bem-modifiers'

import NewsletterPrompt from '~/components/NewsletterPrompt/NewsletterPromptGql'
import PictureTag from '~/components/PictureTag'
import Link from '~/components/Link'
import LanguageSwitcher from '~/components/LanguageSwitcher'

import { getLogo, scrollTo, createRedirectUrl } from '~/utils/helpers'

import config from '~/config'

@withNamespaces(['common'], { withRef: true })
export default class Footer extends React.Component {
  constructor (props) {
    super(props)

    this.footerEl = React.createRef()
  }

  componentDidMount () {
    const { observer } = this.props
    observer && observer.observe(this.footerEl.current)
  }

  componentDidUpdate (prevProps) {
    if (this.props.observer && prevProps.observer !== this.props.observer) {
      this.props.observer.observe(this.footerEl.current)
    }
  }

  get regulationsLink () {
    return createRedirectUrl('regulations')
  }

  get privacyPolicyLink () {
    const { document } = this.props
    return createRedirectUrl(`regulation/${document.id}`)
  }

  render () {
    const { modifiers, t, festivalName, document } = this.props

    return (
      <footer
        ref={this.footerEl}
        id='footer'
        className={bemCx('footer', modifiers)}
      >
        <div className='container footer__container'>
          <PictureTag
            src='assets/pfr/pfr-left.png'
            className='footer__pfr-img-mobile'
            alt='Logo PFR'
            blankMediaQuery='(min-width: 420px)'
          />
          <div className='footer__links-container'>
            <div>
              <PictureTag
                src={getLogo(festivalName, true)}
                blankMediaQuery='(max-width: 991px)'
                className='footer__logo-img'
                alt={`Logo ${festivalName || 'Restaurant Club'}`}
              />
              <div className='footer__company'>
                <p className='newsletter-prompt__feature'>
                  Restaurant Club Sp. z o. o.
                </p>
                <p className='newsletter-prompt__feature'>
                  Aleje Ujazdowskie 24 lok. 23a
                </p>
                <p className='newsletter-prompt__feature'>
                  00-478 Warszawa
                </p>
                <p className='newsletter-prompt__feature'>
                  {config().festival.email}
                </p>
                <p className='newsletter-prompt__feature'>
                  +48 22 113 43 00
                </p>
                <PictureTag
                  src='assets/pfr/pfr-left.png'
                  className='footer__pfr-img'
                  alt='Logo PFR'
                  blankMediaQuery='(max-width: 420px)'
                />
              </div>
            </div>
            <ul className='footer__links'>
              {document.id && <li className='footer__link'>
                <a href={this.privacyPolicyLink}>
                  {t('footer.links.privacy_policy')}
                </a>
              </li>}
              <li className='footer__link'>
                <a href={this.regulationsLink}>
                  {t('footer.links.terms')}
                </a>
              </li>
              <li className='footer__link'>
                <Link route='faq'>
                  {t('footer.links.contact')}
                </Link>
              </li>
              <li className='footer__link'>
                <Link route='become-partner'>
                  {t('footer.links.become_partner')}
                </Link>
              </li>
              <li className='footer__link'>
                <LanguageSwitcher />
              </li>
              <li className='footer__link'>
                <a
                  className='footer__scroll-to-top scroll-to-top'
                  onClick={scrollTo('body')}
                >
                  <span className='scroll-to-top__text'>{t('footer.links.back_to_top')}</span>
                  <span className='scroll-to-top__arrow i-arrowup' />
                </a>
              </li>
            </ul>
          </div>
          <NewsletterPrompt
            placeholder={t('footer.newsletter_prompt.placeholder')}
            source='footer'
          />
        </div>
      </footer>
    )
  }
}
