import React from 'react'
import { Trans } from '>/i18n'
import get from 'lodash/get'

import { AnchorButton } from '~/components/Button'
import HomepageSectionWrapper from '~/components/landing-common/HomepageSectionWrapper'

import { MODAL_NAMES } from '~/utils/consts'
import { createRedirectUrl } from '~/utils/helpers'

import withLandingNamespaces from '~/hoc/withLandingNamespaces'
import { withGraphModalActions } from '~/hoc/withGraphModalActions'

import config from '~/config'

@withGraphModalActions
@withLandingNamespaces()
export default class VoucherSection extends React.Component {
  state = {
    loading: false
  }

  setLoading = () => this.setState({ loading: true })

  openModal = () => this.props.openModal(MODAL_NAMES.NEWSLETTER_EXIT)

  get social () {
    const { festivalName } = this.props
    return get(config(festivalName), 'festival.fb')
  }

  get params () {
    const { festivalId } = this.props
    return { festivalId }
  }

  get redirectUrl () {
    const { festivalId } = this.props
    return createRedirectUrl('voucher-details', { festivalId })
  }

  render () {
    const { t } = this.props
    const { loading } = this.state

    return (
      <HomepageSectionWrapper
        title={t('voucher_section.title')}
        description={t('voucher_section.description')}
        sideDescription={
          <Trans i18nKey='voucher_section.side_description'>
            <a
              className='voucher-section__description-link'
              href={this.social}
              target='_blank'
            >
              __
            </a>
            <span
              className='voucher-section__description-link'
              onClick={this.openModal}
            >
              __
            </span>
          </Trans>
        }
        sectionId='voucher-section'
        modifiers='voucher'
      >
        <div className='voucher-section'>
          <div className='voucher-section__button-wrapper'>
            <AnchorButton
              modifiers={['primary', 'full-width', 'big-text']}
              href={this.redirectUrl}
              label={t('voucher_section.button')}
              onClick={this.setLoading}
              loading={loading}
              disabled={loading}
            />
            {/* TODO: Fetch regulations from API */}
            {/* <a
              className='voucher-section__regulations'
              href={this.regulation}
              target='_blank'
            >
              {t('voucher_section.regulations')}
            </a> */}
          </div>
        </div>
      </HomepageSectionWrapper>
    )
  }
}
