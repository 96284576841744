import { useContext } from 'react'
import { useQuery } from '@apollo/client'
import get from 'lodash/get'

import { FestivalContext } from '~/hoc/withApolloClient'

import { GET_CURRENT_FESTIVAL_EDITION } from '~/utils/queries.gql'
import { getFestival } from '~/utils/helpers'

export default ({ router }) => {
  const { domain } = useContext(FestivalContext)
  const festivalName = getFestival(router, domain)
  const { data } = useQuery(
    GET_CURRENT_FESTIVAL_EDITION,
    {
      variables: {
        code: festivalName
      },
      skip: !festivalName
    }
  )

  const festivalId = get(data, 'currentFestivalEdition.id')

  return {
    router,
    festivalName,
    festivalId
  }
}
