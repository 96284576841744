import { useState, useEffect } from 'react'

import { useScrollLock } from '~/hooks/useScrollLock'
import { useEventListener } from '~/hooks/useEventListener'

import { hasWindow } from '~/utils/helpers'

const MODAL_ANIMATION_DURATION = 300

export const useModalEvents = ({ closeModal, hideOthers, activeModal, uncloseable }) => {
  useScrollLock(hideOthers)

  const [animationName, setAnimation] = useState('modal-in')

  useEffect(() => {
    activeModal && setAnimation('modal-in')
  }, [activeModal])

  const closeModalWithAnimation = ({
    forceClose = false
  } = {}) => {
    if (forceClose || !uncloseable) {
      setAnimation('modal-out')
      closeModal && window.setTimeout(closeModal, MODAL_ANIMATION_DURATION)
    }
  }

  useEventListener('keydown', (e) => {
    if (e.key === 'Escape') {
      closeModalWithAnimation()
    }
  }, hasWindow() && document)

  return {
    animationName,
    closeModalWithAnimation
  }
}
