import { Query } from '@apollo/client/react/components'
import get from 'lodash/get'

import DesktopNavigationContainer from './DesktopNavigationContainer'

import { GET_LOCATION } from '~/utils/queries.gql'

export default props => {

  return (
    <Query
      query={GET_LOCATION}
    >
      {({ data: locationData }) => {

        return (
          <DesktopNavigationContainer
            {...props}
            location={get(locationData, 'location')}
          />
        )
      }}
    </Query>
  )
}
