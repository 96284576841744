const NextI18Next = require('next-i18next/dist/commonjs')

const NextI18NextInstance = new NextI18Next({
  defaultLanguage: 'pl',
  fallbackLng: 'pl',
  browserLanguageDetection: true,
  serverLanguageDetection: true,
  // otherLanguages: ['pl', 'en'], <= Dodac gdy bd translatki
  otherLanguages: ['pl'],
})

module.exports = NextI18NextInstance
