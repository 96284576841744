import React from 'react'
import { withNamespaces } from '>/i18n'
import get from 'lodash/get'

import { hasWindow, setLocationAndPeopleQuery, getPeopleOptions, createRedirectUrl } from '~/utils/helpers'

import DesktopNavigationOnScrollView from './DesktopNavigationOnScrollView'

const MENU_MOBILE_ANIMATION_HEIGHT = 400

export default
@withNamespaces('common')
class DesktopNavigationOnScroll extends React.Component {
  state = {
    people: this.peopleOptions,
    loading: false
  }

  componentDidMount () {
    hasWindow() && window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount () {
    hasWindow() && window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    const mobileNavigation = hasWindow() && document.querySelector('.desktop-navigation-on-scroll')

    if (mobileNavigation) {
      if (hasWindow() && window.scrollY <= MENU_MOBILE_ANIMATION_HEIGHT) {
        mobileNavigation.style.cssText = 'display:none'
      } else if (hasWindow() && window.scrollY >= MENU_MOBILE_ANIMATION_HEIGHT && window.innerWidth >= 992) {
        mobileNavigation.style.cssText = 'display: flex; position:fixed; top:0'
      }
    }
  }

  setLoading = () => this.setState({ loading: true })

  setPeopleValue = value => {
    this.setState({ people: value })
  }

  get priceSentence () {
    const { festivalData } = this.props
    const reservationPrice = festivalData ? festivalData.price : 0
    const price = reservationPrice.toFixed(2).replace('.', ',')

    return price
  }

  get buttonSentence () {
    return this.props.isVoucher ? 'voucher' : 'search'
  }

  get peopleOptions () {
    const { minPeopleCount, maxPeopleCount } = this.props
    return getPeopleOptions({ minPeopleCount, maxPeopleCount })
  }

  get redirectUrl () {
    const { people } = this.state
    const { isVoucher, location, festivalData, festivalId } = this.props

    const params = isVoucher
      ? { festivalId }
      : {
        ...setLocationAndPeopleQuery(people.value, location),
        festivalFilter: get(festivalData, 'id')
      }

    return createRedirectUrl(isVoucher ? 'voucher-details' : 'restaurants', params)
  }

  render () {
    const { festivalName, isVoucher } = this.props

    return (
      <DesktopNavigationOnScrollView
        {...this.state}
        isVoucher={isVoucher}
        festivalName={festivalName}
        guestOptions={this.peopleOptions}
        priceSentence={this.priceSentence}
        redirectUrl={this.redirectUrl}
        buttonSentence={this.buttonSentence}
        setLoading={this.setLoading}
        setPeopleValue={this.setPeopleValue}
      />
    )
  }
}
