import React from 'react'

import withLandingNamespaces from '~/hoc/withLandingNamespaces'

import HomepageSectionWrapper from '~/components/landing-common/HomepageSectionWrapper'
import ImageTag from '~/components/ImageTag'

@withLandingNamespaces()
export default class ExplanationSection extends React.Component {

  get steps () {
    return [
      'step_1',
      'step_2',
      'step_3',
    ]
  }

  render () {
    const { t, festivalName } = this.props

    return (
      <HomepageSectionWrapper
        title={t('explanation_section.title')}
        description={t('explanation_section.description')}
        sideDescription={t('explanation_section.side_description')}
        sectionId='explanation-section'
        modifiers='explanation'
      >
        <div className='homepage-section-wrapper__content'>
          <div className='explanation-section'>
            {this.steps.map((item) =>
              <div
                key={item}
                className='explanation-section__item'
              >
                <div className='explanation-section__icon'>
                  <ImageTag
                    src={`assets/landing-${festivalName}/how-it-works/${item}.png`}
                    alt={t(`explanation_section.steps.${item}.heading`)}
                  />
                </div>
                <div className='explanation-section__item-heading'>
                  {t(`explanation_section.steps.${item}.heading`)}
                </div>
                <div
                  className='explanation-section__item-description'
                  dangerouslySetInnerHTML={{ __html: t(`explanation_section.steps.${item}.description`) }}
                />
              </div>
            )}
          </div>
        </div>
      </HomepageSectionWrapper>
    )
  }
}
