import { useQuery } from '@apollo/client'
import get from 'lodash/get'

import withFestivalContext from '~/hoc/withFestivalContext'

import MobileMenuContainer from './MobileMenuContainer'

import { GET_LOCATION } from '~/utils/queries.gql'

export default withFestivalContext(({ ...props }) => {
  const { data: locationData } = useQuery(GET_LOCATION)
  return <MobileMenuContainer {...props} location={get(locationData, 'location')} />
})
