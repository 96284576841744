// becouse storybook don't work with Router
import { getRouteByPath } from '>/server/routes/helpers'
import { Router } from '>/server/routes'

export const updateQueryParam = (newQuery, router, paramsToClear = [], action = 'push') => {
  const newQueryObject = Object.assign({}, router.query, newQuery)
  const route = getRouteByPath(router.route)
  const hasParamsToClear = Array.isArray(paramsToClear)

  for (let key in newQueryObject) {
    if (newQueryObject[key] === null || newQueryObject[key] === '' ||
      (hasParamsToClear && paramsToClear.includes(key))) {
      delete newQueryObject[key]
    }
  }

  if (action === 'push') {
    Router.pushRoute(route, newQueryObject)
  } else {
    Router.replaceRoute(route, newQueryObject)
  }
}
