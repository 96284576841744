import { useEffect, useState } from 'react'

import ModalView from '~/components/Modal/ModalView'
import { useModalEvents } from '~/hooks/useModalEvents'
import { uncloseables } from '~/components/Modal/modalsList'

const ModalContainer = props => {
  const { closeModal, hideOthers, someModalOpen } = props
  const uncloseable = uncloseables.includes(props.activeModal)

  const { animationName, closeModalWithAnimation } = useModalEvents({
    closeModal,
    hideOthers,
    activeModal: props.activeModal,
    uncloseable
  })

  const [amimateOverlay, setAmimateOverlay] = useState(true)

  useEffect(() => {
    setAmimateOverlay((!someModalOpen))
  }, [someModalOpen])

  return (
    <ModalView
      {...props}
      closeModal={closeModalWithAnimation}
      animation={animationName}
      amimateOverlay={amimateOverlay}
      uncloseable={uncloseable}
    />
  )
}

export default ModalContainer
