import { Query } from '@apollo/client/react/components'
import get from 'lodash/get'

import withFestivalContext from '~/hoc/withFestivalContext'

import TakePartSectionContainer from './TakePartSectionContainer'

import { GET_CURRENT_FESTIVAL_EDITION } from '~/utils/queries.gql'

export default withFestivalContext(({ festivalName, ...props }) => {

  return (
    <Query
      query={GET_CURRENT_FESTIVAL_EDITION}
      variables={{ code: festivalName }}
      skip={!festivalName}
    >
      {({ data }) => {
        return (
          <TakePartSectionContainer
            {...props}
            festivalName={festivalName}
            festivalData={get(data, 'currentFestivalEdition')}
          />
        )
      }}
    </Query>
  )
})
