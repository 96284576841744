import PictureTag from '~/components/PictureTag'

import { getStaticFilePath } from '~/utils/helpers'

export default ({ onClick, festivalCode }) => {
  const srcJpg = getStaticFilePath(`assets/mobile-banner/${festivalCode}.jpg`)
  const srcGif = getStaticFilePath(`assets/mobile-banner/${festivalCode}.gif`)

  if (!srcJpg && !srcGif) return null
  return (
    <div className='mobile-advertisement'>
      <PictureTag
        src={`assets/mobile-banner/${festivalCode}.${srcJpg ? 'jpg' : 'gif'}`}
        blankMediaQuery='(min-width: 768px)'
        alt='Baner reklamowy'
        pictureClassName='mobile-advertisement__photo-wrapper'
        className='mobile-advertisement__photo'
      />
      <div className='mobile-advertisement__close-button' onClick={onClick}>
        <span className='i-close mobile-advertisement__top-element' />
      </div>
    </div>
  )
}
