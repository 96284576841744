import React from 'react'
import bemCx from 'bem-modifiers'

export default class HomepageSectionWrapper extends React.Component {
  render () {
    const {
      children,
      modifiers,
      title,
      description,
      sideDescription,
      sectionId
    } = this.props

    return (
      <section className={bemCx('homepage-section-wrapper', modifiers)} id={sectionId} >
        <h2
          className='homepage-section-wrapper__title'
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <div className='homepage-section-wrapper__desc-grid'>
          {description && <p
            className='homepage-section-wrapper__description'
            dangerouslySetInnerHTML={{ __html: description }}
          />
          }
          {sideDescription && <p
            className='homepage-section-wrapper__side-description'
          >
            {sideDescription}
          </p>}
        </div>
        <div className='homepage-section-wrapper__content'>
          {children}
        </div>
      </section>
    )
  }
}
