import React from 'react'
import { withNamespaces } from '>/i18n'
import bemCx from 'bem-modifiers'

import Link from '~/components/Link'

import { HEADER_LINK_NAMES } from '~/utils/consts'

const {
  RESTAURANTS,
  ABOUT,
  FAQ,
  GROUP_RESERVATIONS,
  // SIDE_EVENTS,
  GIFT_EVENTS,
  PARTNERS,
  // VOUCHER,
  JOIN_US
} = HEADER_LINK_NAMES

const MobileMenuView = ({
  festivalId,
  animation,
  linkCheck,
  handleScrollTo,
  handleLinkClick,
  // locationStr,
  // voucherRedirect,
  // giftEventsLink,
  chefsMenuLink,
  // sideEventsLink,
  listingLink,
  isBlack,
  partnersLink,
  t
}) => (
  <div className={bemCx('mobile-menu', [animation, { 'black': isBlack }])}>
    {linkCheck(RESTAURANTS) && <a
      href={listingLink}
      className='mobile-menu__link'
      onClick={handleLinkClick}
    >
      {t('links.listing')}
    </a>}
    {linkCheck(ABOUT) && <a
      onClick={e => handleScrollTo('explanation-section', 40, e)}
      className='mobile-menu__link'
    >
      {t(`links.about_${festivalId ? 'festival' : 'club'}`)}
    </a>}
    {linkCheck(FAQ) && <Link
      route='faq'
      className='mobile-menu__link'
      onClick={handleLinkClick}
    >
      {t('links.faq')}
    </Link>}
    {linkCheck(GROUP_RESERVATIONS) && <Link
      route='group-reservations'
      className='mobile-menu__link'
      onClick={handleLinkClick}
    >
      {t('links.group_reservations')}
    </Link>}
    {/* {linkCheck(SIDE_EVENTS) && <a
      href={sideEventsLink}
      className='mobile-menu__link'
      onClick={handleLinkClick}
    >
      {t('links.side_events')}
    </a>} */}
    {linkCheck(GIFT_EVENTS) && <a
      href={chefsMenuLink}
      className='mobile-menu__link'
      onClick={handleLinkClick}
    >
      {t('links.chefs_menu')}
    </a>}
    {linkCheck(PARTNERS) && <a
      href={partnersLink.href}
      onClick={partnersLink.onClick}
      className='mobile-menu__link'
    >
      {t('links.partners')}
    </a>}
    {/* {linkCheck(VOUCHER) && <a
      href={voucherRedirect}
      className='mobile-menu__link'
    >
      {t('links.voucher_purchase')}
    </a>} */}
    {linkCheck(JOIN_US) && <a
      onClick={e => handleScrollTo('cooperation-section', 20, e)}
      className='mobile-menu__link'
    >
      {t('links.join_us')}
    </a>}
  </div>
)

export default withNamespaces(['common'])(MobileMenuView)
