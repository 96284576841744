import { Query } from '@apollo/client/react/components'
import get from 'lodash/get'

import withFestivalContext from '~/hoc/withFestivalContext'

import DesktopNavigationOnScrollContainer from './DesktopNavigationOnScrollContainer'
import { FESTIVAL_EDITION_STATES } from '~/utils/consts'
import { GET_LOCATION, GET_CURRENT_FESTIVAL_EDITION } from '~/utils/queries.gql'

export default withFestivalContext(({ router, festivalName, ...props }) => {

  return (
    <Query
      query={GET_LOCATION}
    >
      {({ data: locationData }) => {

        return (
          <Query
            query={GET_CURRENT_FESTIVAL_EDITION}
            variables={{ code: festivalName }}
            skip={!festivalName}
          >
            {({ data: festivalData }) => {
              const festival = get(festivalData, 'currentFestivalEdition')

              if (!festival) return null
              return (
                <DesktopNavigationOnScrollContainer
                  {...props}
                  router={router}
                  location={get(locationData, 'location')}
                  festivalData={festival}
                  minPeopleCount={festival.minPeopleCount}
                  maxPeopleCount={festival.maxPeopleCount}
                  isVoucher={festival.state === FESTIVAL_EDITION_STATES.VOUCHER}
                  festivalName={festivalName}
                />
              )
            }}
          </Query>
        )
      }}
    </Query>
  )
})
