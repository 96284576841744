import { useQuery } from '@apollo/client'
import get from 'lodash/get'

import withFestivalContext from '~/hoc/withFestivalContext'

import HeroSectionContainer from './HeroSectionContainer'

import { GET_FESTIVAL_REGIONS, GET_CURRENT_FESTIVAL_EDITION } from '~/utils/queries.gql'

export default withFestivalContext(({ festivalName, festivalId, slides, heroWithDate, heroWithPrice, heroWithTag }) => {
  const { data: festivalData } = useQuery(GET_CURRENT_FESTIVAL_EDITION, {
    variables: {
      code: festivalName
    },
    skip: !festivalName
  })

  const { data: regionsData } = useQuery(GET_FESTIVAL_REGIONS, {
    variables: {
      festivalEditionId: festivalId
    },
    skip: !festivalId
  })

  return (
    <HeroSectionContainer
      regions={get(regionsData, 'festivalRegions')}
      festivalName={festivalName}
      festivalData={get(festivalData, 'currentFestivalEdition')}
      festivalEditionState={get(festivalData, 'currentFestivalEdition.state')}
      slides={slides}
      heroWithDate={heroWithDate}
      heroWithPrice={heroWithPrice}
      heroWithTag={heroWithTag}
    />
  )
})
