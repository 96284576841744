import { CSSTransitionGroup } from 'react-transition-group'
import React from 'react'

const DropdownTransitionWrapper = ({ children }) => (
  <CSSTransitionGroup
    transitionName='tooltip-animation'
    // timeout must equal animation duration in css
    transitionEnterTimeout={200}
    transitionLeaveTimeout={200}
  >
    {children}
  </CSSTransitionGroup>
)

export default DropdownTransitionWrapper
