import { withNamespaces } from '>/i18n'
import withFestivalContext from '~/hoc/withFestivalContext'
import { isEmpty, compose } from 'ramda'
import { getFestivalRange } from '~/utils/helpers'

const FestivalInfo = ({
  withPrice = true,
  withDate = true,
  festival = {},
  hashTag,
  className,
  t,
}) => {
  if (!festival || isEmpty(festival)) return null

  return (
    <div className={`festival-info ${className}`}>
      {hashTag && <span
        className='hero-section__respect-tag'
        dangerouslySetInnerHTML={{ __html: hashTag }}
      />}
      {withDate && <span>{getFestivalRange(festival)}</span>}
      {festival.price && withPrice && withDate && <span className={`${className}-separator`}> — </span>}
      {festival.price && withPrice && <span>{`${festival.price} ${t('price_per_person')}`}</span>}
    </div>
  )
}

export default compose(
  withNamespaces('common'),
  withFestivalContext
)(FestivalInfo)
