import rw from './festivals/rw'
import fdw from './festivals/fdw'
import rwx from './festivals/rwx'
import korw from './festivals/korw'
import bfw from './festivals/bfw'
import dw from './festivals/dw'
import cf from './festivals/cf'
import rctjz from './festivals/rctjz'
import { DEFAULT_FESTIVAL } from './index'

const FESTIVAL_TYPES = {
  rw,
  fdw,
  rwx,
  korw,
  bfw,
  dw,
  cf,
  rctjz,
}

const FESTIVAL = function (festivalName) {
  const name = festivalName || DEFAULT_FESTIVAL
  return FESTIVAL_TYPES[name] || {}
}

module.exports = {
  FESTIVAL,
  FESTIVAL_TYPES
}
