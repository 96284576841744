import { TAGS, TAG_TO_OMIT, COMMON_HEADER_LINKS, HEADER_LINK_NAMES } from '~/utils/consts'
const { ATMOSPHERE, CATEGORY, ESTABLISHMENT_TYPE, OCCASION, OTHER } = TAGS

export default {
  name: 'Delivery Week',
  id: 'festival-dw',

  url: 'deliveryweek.pl',
  query: 'dw',

  fb: 'https://www.facebook.com/RestaurantWeekPolska',
  instagram: 'https://www.instagram.com/explore/tags/restaurantweekpolska',
  email: 'info@deliveryweek.pl',
  showAd: true,

  gtm_key: 'GTM-W2W9NZ8',

  faq: {
    offset: {
      mobile: 30,
      tablet: 30,
      desktop: 66,
      intersection: 28
    }
  },

  tagsToOmit: [...TAG_TO_OMIT, ATMOSPHERE, CATEGORY, ESTABLISHMENT_TYPE, OCCASION, OTHER],
  headerLinks: [...COMMON_HEADER_LINKS, HEADER_LINK_NAMES.SIDE_EVENTS]
}
