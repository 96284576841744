import { TAG_TO_OMIT, TAGS, COMMON_HEADER_LINKS, HEADER_LINK_NAMES } from '~/utils/consts'

export default {
  name: 'Koneser Restaurant Week',
  id: 'festival-korw',

  url: 'koneser.restaurantweek.pl',
  query: 'korw',

  fb: 'https://www.facebook.com/RestaurantWeekPolska',
  instagram: 'https://restaurantweek.pl',
  email: 'info@restaurantweek.pl',
  showAd: false,

  gtm_key: 'GTM-TBKQM48',

  faq: {
    offset: {
      mobile: 30,
      tablet: 30,
      desktop: 66,
      intersection: 28
    }
  },

  tagsToOmit: [...TAG_TO_OMIT, TAGS.DELIVERY_REGION],
  headerLinks: [...COMMON_HEADER_LINKS, HEADER_LINK_NAMES.SIDE_EVENTS]
}
