import React from 'react'
import get from 'lodash/get'

import withLandingNamespaces from '~/hoc/withLandingNamespaces'

import HomepageSectionWrapper from '~/components/landing-common/HomepageSectionWrapper'
import ImageTag from '~/components/ImageTag'
import PictureTag from '~/components/PictureTag'

import config from '~/config'

const defaultFeed = [
  {
    src: 'insta-feed-1.jpg',
    href: 'https://www.instagram.com/p/BtA0LvVl17W/',
    alt: 'Szef kuchni'
  },
  {
    src: 'insta-feed-2.jpg',
    href: 'https://www.instagram.com/p/BpEpmARFw7m/',
    alt: 'Para przy stoliku'
  },
  {
    src: 'insta-feed-3.jpg',
    href: 'https://www.instagram.com/p/BrCnF53lovO/',
    alt: 'Książka kucharska'
  },
  {
    src: 'insta-feed-4.jpg',
    href: 'https://www.instagram.com/p/BpmUZeQl30S/',
    alt: 'Posiłek na stole'
  },
  {
    src: 'insta-feed-5.jpg',
    href: 'https://www.instagram.com/p/Bg9NldmlTxF/',
    alt: 'Kanapki'
  },
  {
    src: 'insta-feed-6.jpg',
    href: 'https://www.instagram.com/p/Bpy5R4TFbGW/',
    alt: 'Kobieta przy stoliku'
  }
]

const defaultSocials = [
  {
    src: 'instagram.png',
    href: 'https://www.instagram.com/restaurant_week_polska',
    alt: 'Ikona Instagrama'
  },
  {
    src: 'facebook.png',
    href: 'https://www.facebook.com/RestaurantWeekPolska/',
    alt: 'Ikona Facebooka'
  },
]

@withLandingNamespaces()
export default class InstagramSection extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      inView: false
    }

    this.instagramSection = React.createRef()
  }

  componentDidMount () {
    this.initIntersectionObserver()
    this.observer.observe(this.instagramSection.current)
  }

  componentWillUnmount () {
    this.observer.disconnect()
  }

  setInView = () => this.setState({ inView: true })

  initIntersectionObserver = () => {
    const config = {
      rootMargin: '1000px',
    }

    this.observer = new IntersectionObserver(this.handleInView, config)
  }

  handleInView = entries => {
    const isIntersecting = get(entries, '[0].isIntersecting')

    if (isIntersecting && !this.state.inView) {
      this.setInView()
    }
  }

  get instagram () {
    const { festivalName } = this.props
    return get(config(festivalName), 'festival.instagram')
  }

  render () {
    const { inView } = this.state
    const { t, instaFeed = defaultFeed, socials = defaultSocials } = this.props

    return (
      <HomepageSectionWrapper
        modifiers={['centered', 'instagram']}
        title={t('instagram_section.title')}
      >
        <div className='instagram-section__hashtag-wrap' ref={this.instagramSection}>
          <a
            className='instagram-section__hashtag'
            href={this.instagram}
            target='_blank'
            rel='noopener noreferrer'
          >
            {t('instagram_section.hashtag')}
          </a>
          <PictureTag
            src='assets/fireworks.png'
            pictureClassName='instagram-section__fireworks-img-wrapper'
            className='instagram-section__fireworks-img'
            blankMediaQuery='(max-width: 767px)'
            alt=''
          />
        </div>
        <div className='instagram-section__socials'>
          {socials && socials.map((item, index) => (
            <a
              key={index}
              className='instagram-section__social-link'
              href={item.href}
              target='_blank'
              rel='noopener noreferrer'
            >
              <ImageTag
                className='instagram-section__social-icon'
                src={`assets/landing-rw/instagram/${item.src}`}
                alt={item.alt}
              />
            </a>
          ))}
        </div>
        {instaFeed && <div className='instagram-section__feed'>
          {inView && instaFeed.map((item, index) => (
            <a
              key={index}
              className='instagram-section__link'
              href={item.href}
              target='_blank'
              rel='noopener noreferrer'
            >
              <ImageTag
                className='instagram-section__photo'
                src={`assets/landing-rw/instagram/${item.src}`}
                alt={item.alt}
              />
            </a>
          ))}
        </div>}
      </HomepageSectionWrapper>
    )
  }
}
