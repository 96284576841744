import { Router } from '>/server/routes'
import { withRouter } from 'next/router'

import { useGraphModalActions } from '~/hooks/useModal'

import ModalContainer from '~/components/Modal/ModalContainer'

import { urlModal } from './modalsList'

const Modal = ({ router, queryParams, ...props }) => {
  const isUrlModal = queryParams && queryParams.some(queryParam => router.query[queryParam])
  const context = useGraphModalActions()
  let modalData = {}
  const modals = context.modals || []

  let activeModal = (modals[modals.length - 1] || {}).name

  if (!isUrlModal) { // it is context modal
    modalData = {
      closeModal: () => context.closeModal({ name: activeModal }),
    }
  } else {
    queryParams.forEach(queryParam => {
      if (router.query[queryParam] != null) {
        const modalName = urlModal[queryParam]
        const isUnique = !modals.some(modal => modal.name === modalName)
        isUnique && modals.push({ name: modalName, data: undefined, type: 'url' })
      }
    })
    activeModal = (modals[0] || {}).name
    modalData = {
      closeModal: () => {
        const indexOfActiveModal = modals.findIndex(modal => modal.name === activeModal)
        indexOfActiveModal >= 0 && modals.splice(indexOfActiveModal, 1)
        activeModal = (modals[0] || {}).name

        if (props.onClose) {
          props.onClose(props)
        } else {
          Router.back()
        }
      }
    }
  }

  if (!activeModal) return null
  return (
    <ModalContainer
      {...props}
      activeModal={activeModal}
      someModalOpen={!!modals.length}
      closeModal={modalData.closeModal}
    />
  )
}

export default withRouter(Modal)
