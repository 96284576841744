import { useGraphModalActions } from '~/hooks/useModal'

export const withGraphModalActions = (WrappedComponent) => {
  const Hoc = props => {
    const modalContext = useGraphModalActions()
    return <WrappedComponent {...props} {...modalContext} />
  }

  Hoc.getInitialProps = async (ctx) => {
    let wrappedComponentProps = {}
    if (WrappedComponent.getInitialProps) {
      wrappedComponentProps = await WrappedComponent.getInitialProps(ctx)
    }
    return wrappedComponentProps
  }

  return Hoc
}
