import { Mutation, Query } from '@apollo/client/react/components'
import gql from 'graphql-tag'
import get from 'lodash/get'

import NewsletterPromptContainer from './NewsletterPromptContainer'

import { REGULATION_TYPES } from '~/utils/consts'

const NEWSLETTER_SIGN_UP = gql`
  mutation newsletterSignUp(
    $email: String!
    $source: String
  ) {
    newsletterSignUp(
      email: $email
      source: $source
    ) {
      salesmanagoContactId
    }
  }
`

const GET_PRIVACY_POLICY = gql`
  query documents (
    $type: DocumentTypeEnum
  ) {
    documents (
      type: $type
    ) {
      nodes {
        id
        title
      }
    }
  }
`

export default ({ ...props }) => <Query
  query={GET_PRIVACY_POLICY}
  variables={{ type: REGULATION_TYPES.PRIVACY_POLICY }}
>
  {({ data: regulationData }) => {
    return <Mutation
      mutation={NEWSLETTER_SIGN_UP}
    >
      {(newsletterSignUp) => {
        return (
          <NewsletterPromptContainer
            {...props}
            newsletterSignUp={newsletterSignUp}
            privacyPolicyId={get(regulationData, 'documents.nodes[0].id')}
          />
        )
      }}
    </Mutation>
  }}
</Query>
