import { useCallback } from 'react'

import get from 'lodash/get'
import { i18n } from '>/i18n'

const useLanguageSwitcher = ({ t }) => {
  const onLanguageChange = useCallback(({ value }) => i18n.changeLanguage(value), [])
  const languages = get(i18n, 'options.otherLanguages', [])
  const options = languages.map(language => ({ value: language, label: t(`languages:${language}`) }))
  return ({
    languages,
    options,
    onLanguageChange
  })
}

export default useLanguageSwitcher
