import { TAG_TO_OMIT, TAGS, COMMON_HEADER_LINKS } from '~/utils/consts'

export default {
  name: 'Beer Food Week',
  id: 'festival-bfw',

  url: 'beerfoodweek.pl',
  query: 'bfw',

  fb: 'https://www.facebook.com/grimbergenpl/',
  instagram: 'https://www.instagram.com/explore/tags/beerfoodweek',
  email: 'info@restaurantweek.pl',
  showAd: false,

  gtm_key: 'GTM-W9F3CQD',

  faq: {
    offset: {
      mobile: 30,
      tablet: 30,
      desktop: 66,
      intersection: 28
    }
  },

  tagsToOmit: [...TAG_TO_OMIT, TAGS.DELIVERY_REGION],
  headerLinks: [...COMMON_HEADER_LINKS]
}
