import { boolean, object, string } from 'yup'

export const lengthKey = (limit, count) => {
  return limit === 'min'
    ? { key: 'validations.min_length', count: count }
    : { key: 'validations.max_length', count: count }
}

const phoneValidation = string()
  .matches(/^\+?\d*$/, 'validations.integer')
  .min(9, 'validations.tel_length_min')
  .max(13, 'validations.tel_length_max')
  .required('validations.required')

export const billingDataSchema = object().shape({
  firstName: string()
    .max(100, lengthKey('max', 100))
    .required('validations.required'),
  lastName: string()
    .max(100, lengthKey('max', 100))
    .required('validations.required'),
  phoneNumber: phoneValidation,
})

export const registerSchema = object().shape({
  firstName: string()
    .max(100, lengthKey('max', 100))
    .required('validations.required'),
  lastName: string()
    .max(100, lengthKey('max', 100))
    .required('validations.required'),
  email: string()
    .email('validations.email')
    .required('validations.required'),
  password: string()
    .test(
      'no-spaces',
      'validations.no_spaces',
      (value) => value && !value.match(/\s/g)
    )
    .min(8, lengthKey('min', 8))
    .required('validations.required'),
  phoneNumber: phoneValidation,
  regulationAccepted: boolean()
    .oneOf([true], 'validations.required')
    .required('validations.required'),
})

export const loginSchema = object().shape({
  username: string()
    .email('validations.email')
    .required('validations.required'),
  password: string()
    .required('validations.required'),
})

export const remindPasswordSchema = object().shape({
  username: string()
    .email('validations.email')
    .required('validations.required'),
})

export const resetPasswordSchema = object().shape({
  password: string()
    .required('validations.required'),
})

export const newsletterSchema = object().shape({
  newsletter: string()
    .email('validations.email')
    .required('validations.required')
})

export const voucherValidation = object().shape({
  voucher: string().required('validations.required')
})

export const addressAreaSchema = object().shape({
  fullAddress: string()
    .required('validations.required')
})
