import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import get from 'lodash/get'

import withFestivalContext from '~/hoc/withFestivalContext'

import FooterContainer from './FooterContainer'

import { REGULATION_TYPES } from '~/utils/consts'

const GET_PRIVACY_POLICY = gql`
  query documents (
    $type: DocumentTypeEnum
  ) {
    documents (
      type: $type
    ) {
      nodes {
        id
      }
    }
  }
`

export default withFestivalContext(({ festivalName, modifiers }) => {
  const { data } = useQuery(GET_PRIVACY_POLICY, {
    variables: { type: REGULATION_TYPES.PRIVACY_POLICY }
  })

  return (
    <FooterContainer
      festivalName={festivalName}
      modifiers={modifiers}
      document={get(data, 'documents.nodes[0]') || {}}
    />
  )
})
