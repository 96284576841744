import React from 'react'
import { withNamespaces } from '>/i18n'
import bemCx from 'bem-modifiers'
import get from 'lodash/get'

import HomepageSectionWrapper from '~/components/landing-common/HomepageSectionWrapper'

@withNamespaces('common')
export default class PartnersSection extends React.Component {

  render () {
    const { mainSponsors, sponsors, pageModifier, inView, t } = this.props

    return (
      <HomepageSectionWrapper modifiers={['partners', pageModifier]}>
        {mainSponsors && <div className={bemCx('partners-section__container-grid', pageModifier)}>
          {mainSponsors.map((sponsor) => (
            <a
              key={sponsor.id}
              className='main-partner'
              href={sponsor.url}
              target='_blank'
              rel='noreferrer noopener'
            >
              <div className='main-partner__content'>
                <p className='main-partner__label'>
                  {sponsor.label}
                </p>
                {inView && <img
                  className='main-partner__image'
                  src={get(sponsor, 'image.preview')}
                  alt='Logo sponsora głównego'
                />}
              </div>
            </a>
          ))}
        </div>}
        {sponsors && <div className='partners-section__container-flex'>
          {Object.values(sponsors).map(kind => {
            return (
              kind.map((sponsor) => <div key={sponsor.id} className='regular-partner'>
                <p className='regular-partner__label'>
                  {t(sponsor.label)}
                </p>
                <a
                  href={sponsor.url}
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  {inView && <img
                    className='regular-partner__image'
                    src={get(sponsor, 'image.preview')}
                  />}
                </a>
              </div>)
            )
          })}
        </div>}
      </HomepageSectionWrapper>
    )
  }
}
