import Velocity from 'velocity-react/lib/velocity-animate-shim'
import { Router } from '>/server/routes'
import moment from 'moment'
import get from 'lodash/get'
import omit from 'lodash/omit'
import getConfig from 'next/config'
import { isEmpty } from 'ramda'
import urlSlug from 'url-slug'
import { Trans } from '>/i18n'
import queryString from 'query-string'
import Hashids from 'hashids'

import {
  TAG_TO_OMIT,
  MIN_NUMBER_OF_ATTENDEES,
  MAX_NUMBER_OF_ATTENDEES,
} from './consts'

import { GET_CURRENT_FESTIVAL_EDITION, GET_FESTIVAL_EDITION } from '~/utils/queries.gql'
import config from '~/config'
import { FESTIVAL_TYPES } from '~/config/festivalTypes'
import { DEFAULT_FESTIVAL } from '~/config/index'
import { setCookies, getCookie } from '~/utils/cookie'

export const processEnv = (getConfig() || {}).publicRuntimeConfig || {}

export const hasWindow = () => typeof window !== 'undefined'

export const getFestival = (router, domain) => {
  const domainName = hasWindow() ? window.location.host : domain

  if (config().enableFestivalParam) {
    return get(router, 'query.festival') || DEFAULT_FESTIVAL
  } else {
    const currentFestival = Object.values(FESTIVAL_TYPES).find(({ url }) => url === domainName)
    return get(currentFestival, 'query') || DEFAULT_FESTIVAL
  }
}

export const getStaticFilePath = (path = '') => {
  let filePath
  try {
    filePath = require(`../../static/${path}`)
  } catch (error) {
    filePath = ''
  }
  return filePath
}

export const isSVG = path => /\.svg$/.test(path)

export const getScaledPath = (path, scale = 2) => path.replace(/\.([\w]*)$/, `@${scale}x.$1`)

export const getRasterImagePath = (path = '') => {
  if (!isSVG(path)) {
    path = getScaledPath(path)
  }
  return getStaticFilePath(path)
}

export const scrollTo = (targetId, configObject = {
  duration: 500,
}) => async (e) => {
  e && e.preventDefault()
  const el = targetId === 'body' ? document.querySelector('body') : document.getElementById(targetId)

  if (el) {
    Velocity(el, 'scroll', configObject)
  } else {
    await Router.pushRoute('/', targetId)
    const el = document.getElementById(targetId)
    Velocity(el, 'scroll', configObject)
  }
}

export const scrollBarWidth = () => {
  const bodyElement = hasWindow() && document.querySelector('body')
  const fullWidth = hasWindow() && window.innerWidth
  const withoutScrollWidth = bodyElement && bodyElement.clientWidth

  return fullWidth - withoutScrollWidth
}

export const toggleBodyScroll = (
  isModalOpen,
  previousScrollY,
  previousHeaderPosition,
  scrollBarWidth,
  position = 'fixed'
) => {
  if (hasWindow) {
    const htmlElement = document.querySelector('html')
    const headerElement = document.querySelector('.header')

    const isHeaderFixed = previousHeaderPosition === 'fixed'

    if (isModalOpen) {
      const styles = `position:${position}; left:0; right:${scrollBarWidth}px; top:-${previousScrollY}px`
      const headerStyles = `position:absolute; top:${previousScrollY}px`

      htmlElement.style.cssText = styles
      if (headerElement) {
        headerElement.style.cssText = isHeaderFixed ? headerStyles : ''
      }
    } else {
      htmlElement.removeAttribute('style')
      headerElement && headerElement.removeAttribute('style')
      hasWindow() && window.scrollTo(0, previousScrollY)
    }
  }
}

export const redirect = (url, res, { ignoreQueryKeys = [] } = {}) => {
  if (res) {
    res.redirect(url)
  } else {
    const query = Router.router.query
    Router.replace({
      pathname: url,
      query: omit(query, ignoreQueryKeys)
    })
  }
}

export const viewportWidth = () => {
  if (hasWindow() && typeof window.innerWidth !== 'undefined') {
    return window.innerWidth
  }
}

export const formatDate = ({ date, inputFormat, outputFormat }) => {
  if (date instanceof moment) {
    return date.parseZone().format(outputFormat)
  } else {
    return moment.parseZone(date, inputFormat).format(outputFormat)
  }
}

export const getFestivalRange = ({ startsAt, endsAt }) => {
  let festivalStartingDate

  if (moment(startsAt).isSame(moment(endsAt), 'year')) {
    if (moment(startsAt).isSame(moment(endsAt))) {
      festivalStartingDate = ''
    } else if (moment(startsAt).isSame(moment(endsAt), 'month')) {
      festivalStartingDate = formatDate({ date: startsAt, outputFormat: 'D' })
    } else {
      festivalStartingDate = formatDate({ date: startsAt, outputFormat: 'D MMMM' })
    }
  } else {
    festivalStartingDate = formatDate({ date: startsAt, outputFormat: 'D MMMM YYYY' })
  }
  const festivalEndingDate = (festivalStartingDate ? ' - ' : '')
    + formatDate({ date: endsAt, outputFormat: 'D MMMM YYYY' })

  return (festivalStartingDate + festivalEndingDate)
}

export const normalizeDate = (date, inputFormat) => formatDate({ date, inputFormat, outputFormat: 'YYYY-MM-DD' })

export const supportsIntersectionObserver = () => (
  'IntersectionObserver' in window && 'IntersectionObserverEntry' in window
)

export const tagsCategories = (tagsArr = [], field = 'type') => {
  return Array.from(new Set(tagsArr.map(item => item[field])))
}

export const groupTagsByCategory = (tagsArr = [], field = 'type', tagsToOmit = TAG_TO_OMIT) => {
  const categoryArr = tagsCategories(tagsArr, field)

  return categoryArr.reduce((tagsMap, category) => {

    if (!tagsToOmit.includes(category)) {
      tagsMap[category] = tagsArr.filter(item => item[field] === category)
    }

    return tagsMap
  }, {})
}

export const queryCacheExists = (client, query, variables) => {
  try {
    client.readQuery({
      query,
      variables
    })
    return true
  } catch (e) {
    return false
  }
}

export const persistFestivalParam = (query, paramsToPersist, route) => {
  const festivalPersistent = route === 'main' || route === 'faq' || route === 'regulations'

  if (query.festival && festivalPersistent) {
    if (typeof paramsToPersist === 'object') {
      paramsToPersist['festival'] = query.festival
    } else {
      paramsToPersist = {
        festival: query.festival
      }
    }
  }

  return paramsToPersist
}

export const getFestivalState = async (apolloClient, queryParams, domain) => {
  const festivalName = getFestival({ query: queryParams }, domain)
  const festivalId = queryParams.festivalFilter || queryParams.festivalId

  let data

  if (festivalName) {
    data = await apolloClient.query({
      query: GET_CURRENT_FESTIVAL_EDITION,
      variables: { code: festivalName }
    })
  } else if (festivalId) {
    data = await apolloClient.query({
      query: GET_FESTIVAL_EDITION,
      variables: { id: festivalId }
    })
  }

  const festivalData = get(data, 'data.currentFestivalEdition') || get(data, 'data.festivalEdition')

  if (festivalData === null) return null
  return get(festivalData, 'state')
}

export const getFestivalRangeAndPrice = async (apolloClient, code) => {

  let data
  if (code) {
    const { data: festivalData } = await apolloClient.query({
      query: GET_CURRENT_FESTIVAL_EDITION,
      variables: { code },
      skip: !code
    })
    data = festivalData
  }

  if (get(data, 'currentFestivalEdition') === null) return null
  return { startsAt: get(data, 'currentFestivalEdition.startsAt'),
    endsAt: get(data, 'currentFestivalEdition.endsAt'),
    price: get(data, 'currentFestivalEdition.price'),
  }
}

export const formatLocationQuery = location => location && `${location.id}-${location.name}`

export const setLocationAndPeopleQuery = (peopleCount, minPeopleCount, location) => {
  const locationStr = formatLocationQuery(location)

  if (peopleCount !== minPeopleCount) {
    return {
      location: locationStr,
      people: peopleCount
    }
  } else {
    return { location: locationStr }
  }
}

export const getPeopleOptions = ({
  minPeopleCount = MIN_NUMBER_OF_ATTENDEES,
  maxPeopleCount = MAX_NUMBER_OF_ATTENDEES,
}) => {
  const peopleArray = []

  for (let i = minPeopleCount; i <= maxPeopleCount; i++) {
    peopleArray.push({
      label: <Trans i18nKey='common:guests' count={i}>
        <span>{{ peopleCount: i }}</span>
      </Trans>,
      value: i
    })
  }

  return peopleArray
}

export const getGraphQLApiUrl = festivalName => config(festivalName).apiUrl + '/graphql'

export const getCitiesList = (regions) => ((!regions || isEmpty(regions))
  ? ''
  : regions.map(region => region.name).join(', ')
)

export const setSalesmanagoCookie = user => {
  const currentId = get(user, 'salesmanagoContactId') || ''
  const lastId = getCookie(null, 'smclient')

  user && currentId !== lastId && setCookies('smclient', currentId, null, {
    expires: moment().add(10, 'years').toDate(),
    secure: true,
    sameSite: 'lax'
  })
}

export const getLogo = (festivalName, white = false) => {
  let type = 'club'
  if (festivalName) {
    type = config(festivalName).festival.id
  }
  return white ? `assets/logo/logo-${type}-white.svg` : `assets/logo/logo-${type}.svg`
}

export const toSlug = param => urlSlug(param)

export const handleSponsorsLink = ({ selectedFestivalCode, isListingRoute, handleScrollTo }) => {
  const externalUrl = isListingRoute && selectedFestivalCode
  let href = '/'

  if (externalUrl) {
    if (config().enableFestivalParam) {
      href = `/?festival=${selectedFestivalCode}&scrl=1`
    } else {
      href = `https://${config(selectedFestivalCode).festival.url}/?scrl=1`
    }
  }

  return {
    href: externalUrl ? href : undefined,
    onClick: externalUrl ? undefined : e => handleScrollTo('partners-section', 20, e)
  }
}

export const createRedirectUrl = (route, params = {}) => {
  const clubUrl = config().clubUrl

  const cookieConsent = getCookie(null, 'cookieConsent') ? 1 : 0
  const marketingConsent = getCookie(null, '_gdpr_consent') ? 1 : 0
  const adult = getCookie(null, 'adult') ? 1 : 0

  const salt = config().salt
  const hashids = new Hashids(salt)

  const newParams = {
    ...params,
    __f: hashids.encode([cookieConsent, marketingConsent, adult])
  }

  const paramsString = queryString.stringify(newParams)
  const newUrl = `${clubUrl}/${route}`

  return paramsString ? `${newUrl}?${paramsString}` : newUrl
}
