import { useRef, useEffect } from 'react'

export const useEventListener = (eventName, handler, target) => {
  const savedHandler = useRef(null)

  // Store the handler so the reference stays the same between rerenders.
  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    const eventListener = event => savedHandler.current(event)

    const isSupported = target && target.addEventListener
    if (!isSupported) return

    target && target.addEventListener(eventName, eventListener)

    return () => target.removeEventListener(eventName, eventListener)
  }, [eventName, target])
}
