import DropdownSelect from '~/components/DropdownSelect'
import { AnchorButton } from '~/components/Button'
import GeolocationDropdownSelect from '~/components/GeolocationDropdownSelect/GeolocationDropdownSelectGql'

export default ({
  people,
  peopleOptions,
  loading,
  setLoading,
  setPeopleValue,
  buttonLabel,
  locationModifiers,
  peopleModifiers,
  buttonModifiers,
  festivalData,
  redirectUrl,
  iconRight,
}) => {

  return (
    <div className='hero-filters'>
      <div className='hero-filters__dropdowns'>
        <GeolocationDropdownSelect
          instanceId='location-select-hero'
          modifiers={locationModifiers}
          disabled={festivalData === null}
        />
        <DropdownSelect
          modifiers={peopleModifiers}
          valueContainerIcon='user'
          options={peopleOptions}
          defaultValue={people}
          value={people}
          onChange={value => setPeopleValue(value)}
          isSearchable={false}
          hideSelectedOptions={false}
          disabled={festivalData === null}
        />
      </div>
      <AnchorButton
        modifiers={['primary', buttonModifiers]}
        href={redirectUrl}
        label={buttonLabel}
        onClick={setLoading}
        loading={loading}
        disabled={loading || festivalData === null}
        iconRight={iconRight}
      />
    </div>
  )
}
