import React from 'react'
import { withNamespaces } from '>/i18n'

import DropdownSelect from '~/components/DropdownSelect'
import { AnchorButton } from '~/components/Button'
import GeolocationDropdownSelect from '~/components/GeolocationDropdownSelect/GeolocationDropdownSelectGql'
import { Logo } from '~/components/DesktopNavigation/DesktopNavigationView'

const DesktopNavigationOnScrollView = ({
  t,
  festivalName,
  priceSentence,
  buttonSentence,
  loading,
  setLoading,
  setPeopleValue,
  guestOptions,
  people,
  isVoucher,
  redirectUrl,
}) => (
  <div className='desktop-navigation-on-scroll'>
    <div className='desktop-navigation-on-scroll__container'>
      <Logo className='desktop-navigation-on-scroll' festivalName={festivalName} t={t} />
      <div className='desktop-navigation-on-scroll__filters'>
        {isVoucher
          ? <span className='desktop-navigation-on-scroll__voucher-text'>{t('common:header.voucher')}</span>
          : <>
            <GeolocationDropdownSelect
              instanceId='location-select-desktop'
              modifiers={['frameless', 'triangle-menu']}
            />
            <span className='desktop-navigation-on-scroll__separator' />
            <DropdownSelect
              modifiers={['frameless', 'triangle-menu']}
              valueContainerIcon='user'
              options={guestOptions}
              defaultValue={people}
              value={people}
              onChange={setPeopleValue}
              isSearchable={false}
              hideSelectedOptions={false}
            />
            <span className='desktop-navigation-on-scroll__separator' />
            <div className='desktop-navigation-on-scroll__price'>
              {priceSentence}{t(`common:${festivalName === 'dw' ? 'currency_dw' : 'currency'}.pln_per_person`)}
            </div>
          </>
        }
        <AnchorButton
          modifiers={['primary', 'full-width', 'navbar']}
          href={redirectUrl}
          label={t(`common:button.${buttonSentence}`)}
          onClick={() => setLoading(true)}
          loading={loading}
          disabled={loading}
        />
      </div>
    </div>
  </div>
)

export default React.memo(withNamespaces(['common'])(DesktopNavigationOnScrollView))
