import React from 'react'
import bemCx from 'bem-modifiers'
import { withNamespaces } from '>/i18n'

import withFestivalContext from '~/hoc/withFestivalContext'

import { toggleBodyScroll, hasWindow, createRedirectUrl } from '~/utils/helpers'

import MobileAdvertisement from '~/components/MobileAdvertisement'
import MobileMenu from '~/components/MobileMenu/MobileMenuGql'
import { Logo } from '~/components/DesktopNavigation/DesktopNavigationView'

const MENU_MOBILE_ANIMATION_DURATION = 300

const Hamburger = ({ active, onClick }) => (
  <button
    type='button'
    className={bemCx('hamburger', 'slider', { 'is-active': active })}
    onClick={onClick}
  >
    <span className='hamburger-box'>
      <span className='hamburger-inner' />
    </span>
  </button>
)

@withFestivalContext
@withNamespaces(['common'])
export default class MobileNavigation extends React.Component {
  state = {
    isAdEnabled: true,
    isMobileMenuOpen: false,
    animation: null,
    previousScrollY: 0,
    previousHeaderPosition: null,
  }

  componentWillMount () {
    const headerElement = hasWindow() && document.querySelector('.header')
    const headerElementPosition = headerElement && window.getComputedStyle(headerElement).getPropertyValue('position')

    this.setState({
      previousScrollY: hasWindow() && window.scrollY,
      previousHeaderPosition: headerElementPosition,
    })
  }

  componentDidMount () {
    window.addEventListener('resize', this.closeMobileMenuWhenResizedToDesktop)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.closeMobileMenuWhenResizedToDesktop)
  }

  toggleMobileMenu = () => {
    const { previousScrollY, previousHeaderPosition } = this.state
    const mobileNavigation = hasWindow() && document.querySelector('.mobile-navigation__container')

    this.setState({ animation: null })
    window.setTimeout(() => this.setState({ isMobileMenuOpen: !this.state.isMobileMenuOpen }, () => {
      toggleBodyScroll(this.state.isMobileMenuOpen, previousScrollY, previousHeaderPosition, 0)
      mobileNavigation.style.cssText = 'position:fixed; top:0; left:0;'
    }), MENU_MOBILE_ANIMATION_DURATION)
  }

  closeMobileMenuWhenResizedToDesktop = () => {
    if (window.innerWidth >= 768 && this.state.isMobileMenuOpen) {
      toggleBodyScroll(false)
      this.setState({ isMobileMenuOpen: false })
    }
  }

  handleState = (name, value) => {
    this.setState({ [name]: value })
  }

  hideAd = () => this.setState({ isAdEnabled: false })

  get loginRedirect () {
    const { festivalId } = this.props
    return createRedirectUrl('', { festivalFilter: festivalId, loginRedirect: true })
  }

  render () {
    const { isAdEnabled, animation, isMobileMenuOpen } = this.state
    const { router, linkCheck, festival, festivalName, t } = this.props
    const isBlack = router.route === '/kinley' || router.route === '/cola'
    const isAdvertisementShown = router.route === '/' && isAdEnabled && festival.showAd

    return (
      <div className={bemCx('mobile-navigation__container', [{ 'black': isBlack }])}>
        {isAdvertisementShown && <MobileAdvertisement onClick={this.hideAd} festivalCode={festivalName} />}
        <div className='mobile-navigation'>
          <Logo className='mobile-navigation' festivalName={festivalName} isBlack={isBlack} t={t} />
          <div className='mobile-navigation__content'>
            <div
              className='mobile-navigation__user-menu'
            >
              <a
                className='i-user-circle desktop-navigation__user-name-icon'
                href={this.loginRedirect}
              />
            </div>
            <Hamburger active={isMobileMenuOpen} onClick={this.toggleMobileMenu} />
          </div>
        </div>
        {isMobileMenuOpen &&
          <MobileMenu
            isMobileMenuOpen={isMobileMenuOpen}
            animation={animation}
            handleState={this.handleState}
            router={router}
            festival={festival}
            linkCheck={linkCheck}
            isBlack={isBlack}
            selectedFestivalCode={this.props.selectedFestivalCode}
            isListingRoute={this.props.isListingRoute}
          />
        }
      </div>
    )
  }
}
