import bemCx from 'bem-modifiers'
import { withNamespaces } from '>/i18n'

import Link from '~/components/Link'
import ImageTag from '~/components/ImageTag'

import { HEADER_LINK_NAMES } from '~/utils/consts'
import { getLogo } from '~/utils/helpers'

const {
  ABOUT,
  FAQ,
  GROUP_RESERVATIONS,
  // SIDE_EVENTS,
  GIFT_EVENTS,
  PARTNERS,
  // VOUCHER,
  JOIN_US
} = HEADER_LINK_NAMES

export const Logo = ({ className, festivalName, isBlack }) =>
  <div className={`${className}__logo-wrapper`}>
    <Link route='main' className={bemCx(`${className}__logo-container`, { 'double': festivalName })}>
      <ImageTag
        className={`${className}__logo`}
        src={isBlack ? 'assets/logo/logo-club-white.svg' : getLogo(festivalName)}
        alt=''
      />
    </Link>
  </div>

export default withNamespaces(['common'])(({
  modifiers,
  handleScrollTo,
  linkCheck,
  festivalName,
  isBlack,
  partnersLink,
  // voucherLink,
  // giftEventsLink,
  chefsMenuLink,
  loginRedirect,
  clubLandingRedirect,
  // sideEventsLink,
  t
}) => {

  return (
    <div className={bemCx('desktop-navigation', modifiers)}>
      <Logo
        className='desktop-navigation'
        festivalName={festivalName}
        isBlack={isBlack}
        clubLandingRedirect={clubLandingRedirect}
        t={t}
      />
      <div className='desktop-navigation__content'>
        <div className='desktop-navigation__links-list'>
          {linkCheck(ABOUT) && <a
            onClick={e => handleScrollTo('explanation-section', 40, e)}
            className='desktop-navigation__link'
          >
            {t(`links.about_${festivalName ? 'festival' : 'club'}`)}
          </a>}
          {linkCheck(FAQ) && <Link
            route='faq'
            className='desktop-navigation__link'
          >
            {t('links.faq')}
          </Link>}
          {linkCheck(GROUP_RESERVATIONS) && <Link
            route='group-reservations'
            className='desktop-navigation__link'
          >
            {t('links.group_reservations')}
          </Link>}
          {/* {linkCheck(SIDE_EVENTS) && <a
            href={sideEventsLink}
            className='desktop-navigation__link'
          >
            {t('links.side_events')}
          </a>} */}
          {linkCheck(GIFT_EVENTS) && <a
            href={chefsMenuLink}
            className='desktop-navigation__link'
          >
            {t('links.chefs_menu')}
          </a>}
          {linkCheck(PARTNERS) && <a
            href={partnersLink.href}
            onClick={partnersLink.onClick}
            className='desktop-navigation__link'
          >
            {t('links.partners')}
          </a>}
          {/* {linkCheck(VOUCHER) && <a
            href={voucherLink}
            className='desktop-navigation__link'
          >
            {t('links.voucher_purchase')}
          </a>} */}
          {linkCheck(JOIN_US) && <a
            onClick={e => handleScrollTo('cooperation-section', 40, e)}
            className='desktop-navigation__link'
          >
            {t('links.join_us')}
          </a>}
        </div>
        <a
          className={bemCx('desktop-navigation__user-menu')}
          href={loginRedirect}
        >
          <span className='i-user-circle desktop-navigation__user-name-icon' />
          <span className='desktop-navigation__user-name'>
            {t('links.login')}
          </span>
        </a>
      </div>
    </div>
  )
})
