import gql from 'graphql-tag'

import { GET_RESTAURANT_FRAGMENT } from '~/utils/fragments.gql'

export const CONNECT_RESERVATION = gql`
  mutation connectReservation (
    $code: String!,
    $userId: ID!,
  ) {
    connectReservation (
      code: $code,
      userId: $userId,
    ) {
      id
      code
    }
  }
`

export const GET_CURRENT_FESTIVAL_EDITION = gql`
  query currentFestivalEdition ($code: FestivalCodesEnum!) {
    currentFestivalEdition (code: $code) {
      id
      state
      voucher
      startsAt
      endsAt
      price
      minPeopleCount
      maxPeopleCount
    }
  }
`

export const GET_FESTIVAL_EDITION = gql`
  query festivalEdition ($id: ID!) {
    festivalEdition (id: $id) {
      id
      state
      voucher
      startsAt
      endsAt
      price
      minPeopleCount
      maxPeopleCount
      festival {
        id
        code
        name
      }
    }
  }
`

export const GET_RESERVATION_TO_CONNECT = gql`
  query reservationConnectData ($code: String!) {
    reservation (code: $code) {
      id
      code
      user {
        id
      }
    }
  }
`

export const GET_USER_DATA = gql`
  query userData {
    user {
      id
      firstName
      lastName
      email
      phoneNumber
      verified
      salesmanagoContactId
      regulationStatus
    }
  }
`

export const GET_ACTIVE_REGIONS = gql`
  query regions {
    regions (visible: true) {
      id
      name
    }
  }
`

export const GET_FESTIVAL_REGIONS = gql`
  query festivalRegionsList ($festivalEditionId: ID!) {
    festivalRegions (festivalEditionId: $festivalEditionId) {
      id
      name
    }
  }
`

export const GET_ONGOING_FESTIVALS = gql`
  query ongoingFestivals {
    ongoingFestivalEditions {
      id
      code
      price
      startsAt
      endsAt
      minPeopleCount
      maxPeopleCount
      festival {
        id
        code
        url
        name
        slogan
        shortDescription
      }
      slots {
        id
        possibleSlots
      }
    }
  }
`

export const GET_LOCATION = gql`
  query locationData {
    location @client {
      id
      name
    }
  }
`

export const GET_MY_RESERVATIONS = gql`
  query myPurchases (
    $festival: FestivalCodesEnum
    $first: Int
    $after: String
  ) {
    myPurchases (
      festival: $festival
      first: $first
      after: $after
    ) @connection(key: "myPurchases") {
      edges {
        node {
          ... on Reservation {
            id
            canBeUpdated
            code
            date
            hour
            slot
            createdAt
            peopleCount
            priceValues {
              totalValue
            }
            review {
              id
            }
            user {
              id
            }
            reservable {
              ... on Daily {
                id
                restaurant {
                  id
                  name
                  address
                  latitude
                  longitude
                  imageFiles {
                    id
                    fileUrl
                  }
                }
              }
              ... on Event {
                id
                title
                slotless
                restaurant {
                  id
                  address
                  latitude
                  longitude
                  imageFiles {
                    id
                    fileUrl
                  }
                }
              }
              ... on FestivalEditionRestaurant {
                id
                festivalEdition {
                  id
                  festival {
                    id
                    code
                  }
                }
                restaurant {
                  id
                  name
                  address
                  latitude
                  longitude
                  imageFiles {
                    id
                    fileUrl
                  }
                }
              }
            }
            discount {
              id
              code
              festivalEdition {
                id
              }
              type
              value
            }
            reservationCrossSells {
              id
              quantity
              isApplicable
              crossSell {
                id
                name
              }
            }
            status
          }
          ... on Voucher {
            id
            code
            peopleCount
            createdAt
            used
            festivalEdition {
              id
              endsAt
              startsAt
              festival {
                id
                name
                code
                logo {
                  id
                  thumbnail
                }
              }
              state
            }
          }
        }
      }
      totalCount
    }
  }
`

export const GET_RESTAURANT = gql`
  query restaurant ($id: ID!) {
    restaurant (id: $id) {
      ... fullResturant
    }
  }
  ${GET_RESTAURANT_FRAGMENT}
`

export const VALIDATE_VOUCHER = gql`
  query validateVoucher($code: String!) {
    voucher(code: $code) {
      id
      code
    }
  }
`

export const GET_SPONSORS = gql`
  query sponsors (
    $festivalEditionId: ID
    $regionId: ID
    $clubSponsors: Boolean
  ) {
    sponsors (
      festivalEditionId: $festivalEditionId
      regionId: $regionId
      clubSponsors: $clubSponsors
    ) {
      nodes {
        id
        label
        kind
        subkind
        image {
          id
          preview
        }
        url
      }
    }
  }
`

export const GET_BASIC_RESTAURANT = gql`
  query getBasicRestaurant (
    $id: ID!
  ) {
    restaurant (
      id: $id
    ) {
      id
    }
  }
`
