import { Link } from '>/server/routes'
import { withRouter } from 'next/router'
import { persistFestivalParam } from '~/utils/helpers'

export const LinkComponent = withRouter(({ route, params, children, disabled, router, prefetch = false, ...props }) => {
  const { query } = router
  const newParams = persistFestivalParam(query, params, route)

  if (disabled) {
    return <div {...props}>{children}</div>
  } else {
    return <Link
      route={route}
      params={newParams}
      // Next.js default behaviour now is to prefetch automatically every page that is linked to
      // If you want to prefetch specyfic page, prefetch prop needs to be undefined
      // In order to disable prefetching, you need to set prefetch the prop to false
      prefetch={prefetch ? undefined : prefetch}
    >
      <a {...props}>{children}</a>
    </Link>
  }
})

export default LinkComponent
