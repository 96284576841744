import React from 'react'

import MobileMenuView from './MobileMenuView'

import { toggleBodyScroll, scrollTo, formatLocationQuery, handleSponsorsLink, createRedirectUrl } from '~/utils/helpers'

export default class MobileMenuContainer extends React.PureComponent {

  componentWillMount () {
    setTimeout(() => this.props.handleState('animation', 'is-open'), 0)
  }

  handleScrollTo = (sectionId, sectionOffset, e) => {
    this.props.handleState('isMobileMenuOpen', !this.props.isMobileMenuOpen)
    toggleBodyScroll(!this.props.isMobileMenuOpen)
    scrollTo(sectionId, { duration: 350, offset: -sectionOffset })(e)
  }

  handleLinkClick = () => {
    this.props.handleState('isMobileMenuOpen', !this.props.isMobileMenuOpen)
    toggleBodyScroll(false)
  }

  get listingLink () {
    const { festivalId } = this.props
    const params = { location: this.locationStr }
    if (festivalId) params.festivalFilter = festivalId
    return createRedirectUrl('restaurants', params)
  }

  get locationStr () {
    return formatLocationQuery(this.props.location)
  }

  get voucherRedirect () {
    const { festivalId } = this.props
    return createRedirectUrl('voucher-details', { festivalId })
  }

  get giftEventsLink () {
    return createRedirectUrl('gift-events-listing')
  }

  get chefsMenuLink () {
    return createRedirectUrl('chefsmenu')
  }

  get sideEventsLink () {
    return createRedirectUrl('side-events-listing', { location: this.locationStr })
  }

  get partnersLink () {
    const { selectedFestivalCode, isListingRoute } = this.props
    return handleSponsorsLink({ selectedFestivalCode, isListingRoute, handleScrollTo: this.handleScrollTo })
  }

  render () {
    const { animation, linkCheck, festivalId, isBlack } = this.props

    return (
      <MobileMenuView
        festivalId={festivalId}
        animation={animation}
        linkCheck={linkCheck}
        locationStr={this.locationStr}
        voucherRedirect={this.voucherRedirect}
        giftEventsLink={this.giftEventsLink}
        chefsMenuLink={this.chefsMenuLink}
        sideEventsLink={this.sideEventsLink}
        listingLink={this.listingLink}
        handleScrollTo={this.handleScrollTo}
        handleLinkClick={this.handleLinkClick}
        partnersLink={this.partnersLink}
        isBlack={isBlack}
      />
    )
  }
}
