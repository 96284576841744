const routes = require('next-routes')
const staticRoutes = require('./static')
const dynamicRoutes = require('./dynamic')

const definedRoutes = routes()

staticRoutes.forEach((routes) => {
  definedRoutes.add(routes)
})

dynamicRoutes.forEach((routes) => {
  definedRoutes.add(routes)
})

module.exports = definedRoutes
