import React from 'react'

import withFestivalContext from '~/hoc/withFestivalContext'

import DesktopNavigationView from './DesktopNavigationView'

import { scrollTo, formatLocationQuery, handleSponsorsLink, createRedirectUrl } from '~/utils/helpers'
import { DEFAULT_HEADER_PAGES } from '~/utils/consts'

@withFestivalContext
export default class DesktopNavigation extends React.Component {

  handleScrollTo = (sectionId, sectionOffset, e) => {
    scrollTo(sectionId, { duration: 350, offset: -sectionOffset })(e)
  }

  get locationStr () {
    return formatLocationQuery(this.props.location)
  }

  get partnersLink () {
    const { selectedFestivalCode, isListingRoute } = this.props
    return handleSponsorsLink({ selectedFestivalCode, isListingRoute, handleScrollTo: this.handleScrollTo })
  }

  get voucherLink () {
    const { festivalId } = this.props
    return createRedirectUrl('voucher-details', { festivalId })
  }

  get giftEventsLink () {
    return createRedirectUrl('gift-events-listing')
  }

  get chefsMenuLink () {
    return createRedirectUrl('chefsmenu')
  }

  get sideEventsLink () {
    return createRedirectUrl('side-events-listing', { location: this.locationStr })
  }

  get loginRedirect () {
    const { festivalId } = this.props
    return createRedirectUrl('', { festivalFilter: festivalId, loginRedirect: true })
  }

  get clubLandingRedirect () {
    return createRedirectUrl('', {})
  }

  render () {
    const { router } = this.props

    const isIndexRoute = router.route === '/'
    const isRestaurants = router.route === '/restaurants'
    const isBlack = router.route === '/kinley' || router.route === '/cola'
    const isDefaultHeader = DEFAULT_HEADER_PAGES.includes(router.route)

    const modifiers = {
      'index': isIndexRoute,
      'default': isDefaultHeader,
      'restaurants': isRestaurants,
      'black': isBlack
    }

    return (
      <DesktopNavigationView
        {...this.state}
        {...this.props}
        modifiers={modifiers}
        handleScrollTo={this.handleScrollTo}
        partnersLink={this.partnersLink}
        voucherLink={this.voucherLink}
        giftEventsLink={this.giftEventsLink}
        chefsMenuLink={this.chefsMenuLink}
        sideEventsLink={this.sideEventsLink}
        loginRedirect={this.loginRedirect}
        clubLandingRedirect={this.clubLandingRedirect}
        isBlack={isBlack}
      />
    )
  }
}
