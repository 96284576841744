import React, { useCallback, useMemo } from 'react'
import bemCx from 'bem-modifiers'
import { useQuery } from '@apollo/client'
import { withRouter } from 'next/router'
import get from 'lodash/get'
import { isEmpty } from 'ramda'

import useFestivalContext from '~/hooks/useFestivalContext'

import MobileNavigation from '~/components/MobileNavigation'
import DesktopNavigation from '~/components/DesktopNavigation/DesktopNavigationGql'
import DesktopNavigationOnScroll from '~/components/DesktopNavigationOnScroll/DesktopNavigationOnScrollGql'

import { GET_CURRENT_FESTIVAL_EDITION, GET_SPONSORS, GET_FESTIVAL_EDITION } from '~/utils/queries.gql'
import { FESTIVAL_EDITION_STATES, HEADER_LINK_NAMES, COMMON_HEADER_LINKS } from '~/utils/consts'
import config from '~/config'

const {
  ABOUT,
  RESTAURANTS,
  GROUP_RESERVATIONS,
  SIDE_EVENTS,
  // GIFT_EVENTS,
  VOUCHER,
  JOIN_US,
  PARTNERS
} = HEADER_LINK_NAMES

const isFestivalVoucherable = data => {
  if (!get(data, 'currentFestivalEdition')) return

  const { state, voucher } = data.currentFestivalEdition
  return voucher && (state !== FESTIVAL_EDITION_STATES.VOUCHER)
}

const Header = ({ router, modifiers, isVoucher }) => {
  const route = router.route
  const isIndexRoute = route === '/'
  const isBrandRoute = route === '/kinley' || route === '/delice' || route === '/cola'
  const isListingRoute = route === '/restaurants'
  const isGiftRoute = route === '/gift-events-listing'

  const { festivalName } = useFestivalContext({ router })

  const { data: festivalData } = useQuery(GET_CURRENT_FESTIVAL_EDITION, {
    variables: {
      code: festivalName
    },
    skip: !festivalName
  })
  const festivalEditionId = get(festivalData, 'currentFestivalEdition.id')

  const { data: selectedFestivalData } = useQuery(GET_FESTIVAL_EDITION, {
    variables: { id: router.query.festivalFilter },
    skip: !router.query.festivalFilter
  })
  const selectedFestivalCode = get(selectedFestivalData, 'festivalEdition.festival.code')

  const { data: sponsorsData, loading: sponsorsLoading } = useQuery(GET_SPONSORS, {
    variables: {
      festivalEditionId,
      // It's easier for backend now if 'clubSponsors' is 'undefined' instaed of be 'false'
      clubSponsors: !festivalName ? true : undefined,
    },
    skip: isListingRoute || (festivalName && !festivalEditionId)
  })

  const festival = get(config(festivalName), 'festival', {})

  // Needed because skip in sponsors query doesn't work
  let isSponsor = true
  if (!isListingRoute) {
    isSponsor = festivalName && !festivalEditionId
      ? false
      : sponsorsLoading ? true : get(sponsorsData, 'sponsors') && !isEmpty(get(sponsorsData, 'sponsors.nodes'))
  }

  const isVoucherable = useMemo(() => isFestivalVoucherable(festivalData), [festivalData])

  const linkCheck = useCallback(linkName => {
    const festivalLinks = festival.headerLinks || COMMON_HEADER_LINKS

    const voucherableLinks = [VOUCHER]
    const voucherLinksHidden = [RESTAURANTS, GROUP_RESERVATIONS, SIDE_EVENTS]
    const giftLinksHidden = [ABOUT, PARTNERS]
    const brandLinks = [JOIN_US]

    if (linkName === PARTNERS && !isSponsor) return false
    if (isGiftRoute && giftLinksHidden.includes(linkName)) return false
    if (festivalLinks.includes(linkName)) {
      if (voucherableLinks.includes(linkName)) {
        return isVoucherable
      } else if (voucherLinksHidden.includes(linkName)) {
        return !isVoucher
      } else {
        return true
      }
    } else {
      if (brandLinks.includes(linkName) && isBrandRoute) {
        return true
      }
      return false
    }
  }, [festival, isSponsor])

  const commonProps = {
    festival,
    linkCheck,
    selectedFestivalCode,
    isListingRoute,
    skipUserQuery: isIndexRoute && !!festivalName
  }

  return (
    <>
      <header id='header' className={bemCx('header', modifiers)}>
        <MobileNavigation {...commonProps} />
        <DesktopNavigation {...commonProps} />
      </header>
      {isIndexRoute && <DesktopNavigationOnScroll router={router} />}
    </>
  )
}

export default withRouter(Header)
