import { useEffect } from 'react'
import { useQuery, useApolloClient } from '@apollo/client'
import get from 'lodash/get'
import { Router } from '>/server/routes'

import { GET_ACTIVE_REGIONS, GET_LOCATION } from '~/utils/queries.gql'
import { getCookieAsParsedJson, setCookiesAsJson, deleteCookie } from '~/utils/cookie'
import { VOIVODESHIP_CAPITAL, DEFAULT_LOCATION } from '~/utils/consts'
import { persistFestivalParam } from '~/utils/helpers'
import { manageGeocoding } from '~/api/index'

export default ({ selectedLocation, setUrl = false, router }) => {
  const { data: regionsData } = useQuery(GET_ACTIVE_REGIONS)
  const regions = get(regionsData, 'regions')

  const client = useApolloClient()

  const { data: graphLocationData } = useQuery(GET_LOCATION)
  const graphLocation = get(graphLocationData, 'location')

  let googleLocation = null
  let location = graphLocation

  const retrieveCityName = address => {
    const addressComponents = address[0].address_components
    for (let component of addressComponents) {
      if (component.types.indexOf('locality') >= 0) {
        return component.short_name
      }
    }
  }

  const retrieveVoivodeship = address => {
    for (let component of address) {
      if (component.types.indexOf('administrative_area_level_1') >= 0) {
        return component.address_components[0].short_name
      }
    }
  }

  const getLocationObj = city => {
    const region = regions.find(region => region.name === city)
    return region ? { id: region.id, name: region.name } : null
  }

  const getGoogleLocation = address => {
    const cityName = retrieveCityName(address)
    const voivodeshipName = retrieveVoivodeship(address)

    googleLocation = getLocationObj(cityName || VOIVODESHIP_CAPITAL[voivodeshipName])
  }

  const fetchAddress = pos => {
    const latlng = `${pos.coords.latitude},${pos.coords.longitude}`
    manageGeocoding.fetch(latlng)
      .then((res) => {
        res.results.length && getGoogleLocation(res.results)
      })
  }

  const updateQuery = location => {
    const { route, query } = router

    const oldLocation = query.location
    const newLocationStr = location && `${location.id}-${location.name}`

    if (oldLocation !== newLocationStr) {
      Router.pushRoute(
        route.substr(1),
        {
          ...query,
          ...persistFestivalParam(query),
          location: newLocationStr,
        }
      )
    }
  }

  const formatLocationQuery = queryLocation => {
    const queryLocationArr = queryLocation.split('-')
    return {
      id: queryLocationArr[0],
      name: queryLocationArr[1]
    }
  }

  const isLocationQueryValid = ({ id, name }) => {
    if (!id || !name) return false
    return regions && regions.find(region => region.id === id && region.name === name)
  }

  const findDefaultLocation = () => {
    return regions.find(region => region.name === DEFAULT_LOCATION.label)
  }

  const handleLocation = () => {
    const queryLocation = get(router, 'query.location')
    let cookieLocation = null

    const formattedLocationQuery = queryLocation && formatLocationQuery(queryLocation)

    try {
      cookieLocation = getCookieAsParsedJson(null, 'location')
    } catch (err) {
      deleteCookie(null, 'location')
    }

    if (selectedLocation) {
      location = {
        id: selectedLocation.id,
        name: selectedLocation.name
      }
      setCookiesAsJson('location', location)
    } else if (queryLocation && isLocationQueryValid(formattedLocationQuery)) {
      location = formattedLocationQuery
    } else if (cookieLocation) {
      location = cookieLocation
    } else if (googleLocation) {
      navigator.geolocation.getCurrentPosition(fetchAddress)
      location = googleLocation
      setCookiesAsJson('location', googleLocation)
    } else {
      const defaultLocationInRegions = findDefaultLocation()
      location = defaultLocationInRegions && findDefaultLocation()
    }

    setUrl && updateQuery(location)

    location && client.writeQuery({
      query: GET_LOCATION,
      data: {
        location: {
          __typename: 'Location',
          id: location.id,
          name: location.name
        }
      }
    })
  }

  useEffect(
    () => { regions && handleLocation() },
    [selectedLocation, regions, location]
    // location is in the deps to double check if we have correct data
    // (i.e when user logs out apollo store gets reset to the initial state which possibly has incorrect values -
    // for example id of a region is not the same as it is in the db)
  )

  return location
}
