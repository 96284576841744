import React from 'react'
import bemCx from 'bem-modifiers'
import { withRouter } from 'next/router'
import moment from 'moment'

import { ACCESS, MODAL_NAMES, FESTIVAL_EDITION_STATES, REGULATION_STATUS } from '~/utils/consts'
import { getCookie, getCookieAsParsedJson } from '~/utils/cookie'
import { getFestivalState, getFestival, getFestivalRangeAndPrice, redirect } from '~/utils/helpers'
import { basicAuthMiddleware } from '~/utils/auth'
import config, { DEFAULT_FESTIVAL } from '~/config'

import { withGraphModalActions } from '~/hoc/withGraphModalActions'

import Header from '~/components/Header'

const getEntryData = async (apolloClient, queryParams, domain) => {
  const state = await getFestivalState(apolloClient, queryParams, domain)

  return { festivalState: state }
}

const getSsrCookie = (req, name) => ({ [name]: getCookieAsParsedJson(req, name) })

const ADULT_FESTIVAL_NAME = ['fdw', 'bfw', 'cf', 'korw', 'dw', 'rw']
const XMAS_REMINDER = ['rw']
const DELIVERY_REMINDER = ['rw']

export default ({
  access = ACCESS.ALL,
  fullWidth = false,
  withHeader = true,
  namespaces = [],
  contentClassName = '',
  authModalProps = {},
  accessController,
  ssrCookieName,
}) => (WrappedComponent) => {
  @withRouter
  @withGraphModalActions
  class withLayout extends React.Component {

    static async getInitialProps (props) {
      const { query, pathname, req, res, apolloClient } = props
      const isIndex = pathname === '/'
      const isFaq = pathname === '/faq'

      await basicAuthMiddleware(req, res, {})

      const domain = !!req && (req.headers.host || req.headers.origin)

      const festivalName = getFestival({ query }, domain)

      const namespacesRequired = ['common', 'errors', 'meta', 'languages', ...namespaces]
      const homepageNamespace = isIndex && `homepage-${festivalName || DEFAULT_FESTIVAL}`
      const faqNamespace = isFaq && `faq-${festivalName || DEFAULT_FESTIVAL}`
      homepageNamespace && namespacesRequired.push(homepageNamespace)
      faqNamespace && namespacesRequired.push(faqNamespace)

      const entryData = await getEntryData(apolloClient, query, domain)

      const data = await getFestivalRangeAndPrice(apolloClient, festivalName)
      const { startsAt, endsAt, price } = data || {}

      const err = accessController && await accessController({
        req,
        res,
        apolloClient,
        queryParams: query,
        domain
      })

      const srrCookie = ssrCookieName && getSsrCookie(req, ssrCookieName)

      return {
        namespacesRequired,
        access,
        entryData,
        festivalName,
        startsAt,
        endsAt,
        price,
        srrCookie,
        err,
      }
    }

    componentDidMount () {
      this.pornCheck()
      this.xmasCheck()
      this.deliveryCheck()
      this.cookieCheck()
    }

    get isPornCheckNeeded () {
      return ADULT_FESTIVAL_NAME.includes(this.props.festivalName)
    }

    pornCheck = () => {
      if (this.isPornCheckNeeded) {
        const isAdult = getCookie(null, 'adult')
        if (!isAdult) {
          this.props.openModal(MODAL_NAMES.CONFIRM_LEGAL_AGE, {
            onReject: () => redirect(this.festival.fb, null)
          })
        }
      }
    }

    xmasCheck = () => {
      const isChristmasSeason = moment().isBefore(moment('31.01.2020', 'DD.MM.YYYY'))
      isChristmasSeason && this.handleSessionStorageModal(MODAL_NAMES.XMAS_REMINDER, XMAS_REMINDER)
    }

    regulationCheck = () => {
      const { entryData, openModal } = this.props
      const { regulationStatus } = entryData
      if (regulationStatus && regulationStatus.regulationStatus !== REGULATION_STATUS.ACCEPTED) {
        openModal(MODAL_NAMES.REGULATION, { status: regulationStatus.regulationStatus })
      }
    }

    deliveryCheck = () => {
      const isPandemicSeason = moment().isBefore(moment('13.04.2020', 'DD.MM.YYYY'))
      isPandemicSeason && this.handleSessionStorageModal(MODAL_NAMES.DELIVERY_REMINDER, DELIVERY_REMINDER)
    }

    cookieCheck = () => {
      const cookieConsent = getCookie(null, 'cookieConsent')
      !cookieConsent && this.props.openModal(MODAL_NAMES.COOKIE)
    }

    handleSessionStorageModal = (modalName, festivalCondition) => {
      const { festivalName, router, openModal } = this.props

      const wasOpened = sessionStorage.getItem(modalName)
      const isConfirmationPage = router.pathname === '/reservation-confirmation'

      if (!wasOpened && !isConfirmationPage && festivalCondition.includes(festivalName)) {
        openModal(modalName)
        sessionStorage.setItem(modalName, 'shown')
      }
    }

    getPageByRoute = (route = '') => route.replace(/\//g, '')

    get festival () {
      const { festivalName } = this.props
      return config(festivalName).festival
    }

    render () {
      const { entryData, router } = this.props
      const route = this.getPageByRoute(router.route)

      return (
        <div className='layout'>
          {withHeader && <Header
            modifiers={route.toLowerCase()}
            isVoucher={entryData.festivalState === FESTIVAL_EDITION_STATES.VOUCHER}
          />}
          <main className={bemCx('layout__content', {}, contentClassName)}>
            <WrappedComponent {...this.props} />
          </main>
        </div>
      )
    }
  }

  return withLayout
}
