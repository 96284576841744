import HeroVoucherContent from '~/components/HeroVoucherContent'
import FestivalInfo from '~/components/FestivalInfo'
import HeroFilters from '~/components/HeroFilters/HeroFiltersContainer'

const HeroContent = ({
  isVoucher,
  festivalName,
  festivalData,
  regions,
  buttonLabel,
  t
}) => {

  return (
    isVoucher
      ? <HeroVoucherContent
        festivalName={festivalName}
        festivalData={festivalData}
        regions={regions}
      />
      : <div className='rw-hero-content'>
        <div>
          <FestivalInfo
            festival={festivalData}
            className='rw-hero-content__event'
            withPrice={false}
            withDate
            hashTag={false}
          />
        </div>
        <div>
          <span className='rw-hero-content__hash'>#</span>
          <h1
            className='rw-hero-content__title'
            dangerouslySetInnerHTML={{ __html: t('hero_section.title') }}
          />
        </div>
        <h2
          className='rw-hero-content__subtitle'
          dangerouslySetInnerHTML={{ __html: t('hero_section.subtitle') }}
        />
        <HeroFilters
          locationModifiers={['geolocation', 'hero']}
          peopleModifiers={['people']}
          buttonModifiers={['full-width', 'hero']}
          buttonLabel={buttonLabel}
          iconRight='arrow-long'
          festivalData={festivalData}
        />
        {festivalData &&
          <div className='rw-hero-content__info'>
            <span className='rw-hero-content__price'>{t('hero_section.only', { price: festivalData.price })}</span>
            <span className='rw-hero-content__gratis'>{t('hero_section.gratis')}</span>
          </div>
        }
      </div>
  )
}

export default HeroContent
