import auth from 'basic-auth'
import compare from 'tsscmp'
import { isEmpty } from 'ramda'

import { deleteCookie } from '~/utils/cookie'
import { ACCESS } from '~/utils/consts'
import { redirect } from '~/utils/helpers'

import { GET_USER_DATA } from '~/utils/queries.gql'

const _customRedirect = (redirectFn, res, query, req) => {
  if (redirectFn) {
    redirectFn(query, res, req)
  } else {
    redirect('/', res)
  }
}

export const clearUserDataCookie = (res) => {
  deleteCookie(res, 'order')
  deleteCookie(res, 'adult')
}

const compareCredentials = (currentData, credentials) => {
  if (!currentData || isEmpty(currentData)) return false
  if (compare(currentData.user, credentials.name) && compare(currentData.pass, credentials.pass)) {
    return true
  } else {
    return false
  }
}

export const basicAuthMiddleware = (req, res) => {
  const environmentCredentials = process.env.BASIC_AUTH_CREDENTIALS || ''
  if (!environmentCredentials) return

  const credentials = {
    user: environmentCredentials.split(':')[0],
    pass: environmentCredentials.split(':')[1]
  }
  const currentData = auth(req)

  if (!compareCredentials(currentData, credentials)) {
    res.statusCode = 401
    res.setHeader('WWW-Authenticate', `Basic realm=protected`)
    res.end('401 Access Denied')
  }
}

/**
 * Auth flow used at withLayout.
 *
 * @param {Object} res - next response
 * @param {Object} req - next request
 * @param {Object} apollo - apollo client instance
 * @param {function} [redirectFn] - redirect function from `page` (withLayout wrapped component)
 * @param {Object} query - url query params from next-routes
 * @param {string} access - `page` access level (withLayout wrapped component)
 */
export default async ({ res, req, apollo, access, redirectFn, query }) => {

  const userData = await apollo.query({
    query: GET_USER_DATA,
  })

  const user = (userData.data && userData.data.user) || {}

  if (access === ACCESS.LOGGED && !user.id) {
    _customRedirect(redirectFn, res, query, req)
    return { allowed: false }
  }
  return { allowed: true }
}
