import React from 'react'
import Head from 'next/head'
import { getDataFromTree } from '@apollo/client/react/ssr'
import * as Sentry from '@sentry/node'

import auth from '~/utils/auth'
import initApollo from '~/utils/init-apollo'

export const FestivalContext = React.createContext({})
export const FestivalContextConsumer = FestivalContext.Consumer

export default App => {
  return class Apollo extends React.Component {
    static async getInitialProps (appContext) {
      const { Component, router, ctx } = appContext
      const { res, req, query } = ctx

      const domain = !!req && (req.headers.host || req.headers.origin)

      let appProps = {}

      const access = (appProps.pageProps && appProps.pageProps.access)
      const redirectFn = (appProps.pageProps && appProps.pageProps.redirectFn)

      // Run all GraphQL queries in the component tree
      // and extract the resulting data
      const apollo = await initApollo(null, ctx)
      const authData = await auth({ res, req, apollo, access, redirectFn, query })

      if (App.getInitialProps) {
        ctx.apolloClient = apollo
        appProps = await App.getInitialProps(appContext)
      }

      if (!process.browser && authData.allowed) {
        try {
          const Tree = () => (
            <FestivalContext.Provider value={{ domain }}>
              <App
                {...appProps}
                Component={Component}
                router={router}
                domain={domain}
                apolloClient={apollo}
              />
            </FestivalContext.Provider>
          )
          await getDataFromTree(<Tree />)

        } catch (error) {
          // Prevent Apollo Client GraphQL errors from crashing SSR.
          // Handle them in components via the data.error prop:
          // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
          console.error('Error while running server-side tree render.', error) // eslint-disable-line no-console
          Sentry.captureException(error)
        }

        // getDataFromTree does not call componentWillUnmount
        // head side effect therefore need to be cleared manually
        Head.rewind()
      }

      // Extract query data from the Apollo store
      const apolloState = apollo.cache.extract()

      return {
        ...appProps,
        apolloState,
        domain,
      }
    }

    static displayName = 'withApollo(App)'

    constructor (props) {
      super(props)
      this.apolloClient = initApollo(props.apolloState)
    }

    render () {
      const { domain } = this.props

      return <FestivalContext.Provider value={{ domain }}>
        <App {...this.props} apolloClient={this.apolloClient} domain={domain} />
      </FestivalContext.Provider>
    }
  }
}
